import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Correct path for Auth

const firebaseConfig = {
  apiKey: "AIzaSyD_-PP1hCR8saePZtQ9EbezXQ1Tw9_u_AM",
  authDomain: "ibonn-ifaste.firebaseapp.com",
  projectId: "ibonn-ifaste",
  storageBucket: "ibonn-ifaste.appspot.com",
  messagingSenderId: "67707905878",
  appId: "1:67707905878:web:f3aca33bac52b1744218f7",
  measurementId: "G-VQWM2SGC73",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
