import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Alert } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import Header from "stories/Header/Header";
import PrayerTimesSkeleton from "./PrayerTimesSkeleton/PrayerTimesSkeleton";
import styles from "./prayerTimes.module.scss";
import useFetch from "hooks/useFetch";

interface PrayerTimes {
  date: string;
  fajr: string;
  fajr_endtime: string;
  duhr: string;
  asr: string;
  maghrib: string;
  isha: string;
  asr_1x_shadow: string;
  asr_2x_shadow: string;
  hijriDate: string;
}

interface PrayerTimeTable {
  id: number;
  name: string;
}

interface Location {
  id: number;
  name: string;
  prayerTimesTables: PrayerTimeTable[];
}

const MawaqeetPrayerTimes: React.FC = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [prayerTimesData, setPrayerTimesData] = useState<PrayerTimes[]>([]);
  const [yearPrayerTimesData, setYearPrayerTimesData] = useState<PrayerTimes[]>(
    [],
  );
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    null,
  );
  const [error, setError] = useState<string | null>(null);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const selectedTableId = 2;

  const { execute, isLoading } = useFetch<PrayerTimes[]>();
  const { execute: fetchLocations } = useFetch<Location[]>();

  // Fetch locations for dropdown
  useEffect(() => {
    const fetchLocationData = async () => {
      const result = await fetchLocations("/api/v1/locations", "GET");
      if (result) {
        const filteredLocations = result
          .filter((location) =>
            location.prayerTimesTables.some((table) => table.id === 2),
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        setLocations(filteredLocations);
      }
    };
    fetchLocationData();
  }, []);

  const fetchPrayerTimes = async () => {
    if (!selectedLocationId) return;

    const month = (currentMonth.getMonth() + 1).toString().padStart(2, "0");
    const endpoint = `/api/v1/prayerTimes/${selectedLocationId}/${selectedTableId}/${currentYear}/${month}`;

    try {
      const result = await execute(endpoint, "GET");
      if (result?.length === 0) {
        setError("Ingen tilgjengelige bønnetider for den valgte perioden.");
        setPrayerTimesData([]);
      } else {
        setPrayerTimesData(result || []);
        setError(null);
      }
    } catch (err) {
      setPrayerTimesData([]);
      setError(
        "Kunne ikke hente bønnetider for den valgte lokasjonen og perioden. Vennligst prøv igjen.",
      );
    }
  };

  const fetchYearPrayerTimes = async () => {
    if (!selectedLocationId || currentYear === null) return;

    const endpoint = `/api/v1/prayerTimes/${selectedLocationId}/${selectedTableId}/${currentYear}`;
    setIsCsvLoading(true);
    try {
      const result = await execute(endpoint, "GET");
      setYearPrayerTimesData(result || []);
      setIsCsvLoading(false);
    } catch (err) {
      setYearPrayerTimesData([]);
      setError(
        "Kunne ikke hente bønnetider for den valgte lokasjonen og tabellen.",
      );
      setIsCsvLoading(false);
    }
  };

  const validateCsv = async (file: File): Promise<boolean> => {
    const expectedHeaders = [
      "date",
      "hijriDate",
      "fajr",
      "fajr_endtime",
      "duhr",
      "asr",
      "asr_1x_shadow",
      "asr_2x_shadow",
      "maghrib",
      "isha",
      "locationId",
      "prayerTimeTable",
    ];

    const text = await file.text();
    const rows = text.split("\n");
    const headers = rows[0]?.split(";");

    for (let i = 0; i < headers.length; i++) {
      if (headers[i]?.trim() !== expectedHeaders[i]) {
        alert(
          `Invalid CSV: Expected column '${expectedHeaders[i]}' but got '${headers[i]?.trim()}'.`,
        );
        return false;
      }
    }

    return true;
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const isValid = await validateCsv(file);
      if (!isValid) return;

      const formData = new FormData();
      formData.append("file", file);

      try {
        const endpoint = `/api/v1/prayerTimes/${selectedLocationId}/${selectedTableId}/${currentYear}/upload-csv`;
        await execute(endpoint, "POST", formData);
        alert("CSV uploaded successfully.");
        fetchPrayerTimes();
      } catch (err) {
        alert("Failed to upload CSV.");
      }
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (selectedLocationId) {
      fetchPrayerTimes();
    }
  }, [selectedLocationId, currentMonth, currentYear]);

  const formatCsvData = (data: PrayerTimes[]) => {
    return data.map((row) => ({
      date: formatDateToDDMMYYYY(row.date),
      hijriDate: row.hijriDate,
      fajr: row.fajr,
      fajr_endtime: row.fajr_endtime,
      duhr: row.duhr,
      asr: row.asr,
      asr_1x_shadow: row.asr_1x_shadow,
      asr_2x_shadow: row.asr_2x_shadow,
      maghrib: row.maghrib,
      isha: row.isha,
      locationId: selectedLocationId,
      prayerTimeTable: selectedTableId,
    }));
  };

  const formatDateToDDMMYYYY = (dateString: string): string => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleLocationChange = async (selectedOption: any) => {
    const locationId = selectedOption?.value || null;
    setSelectedLocationId(locationId);

    if (locationId) {
      try {
        const endpoint = `/api/v1/prayerTimes/${locationId}/${selectedTableId}/${currentYear}`;
        setIsCsvLoading(true);
        const result = await execute(endpoint, "GET");
        setYearPrayerTimesData(result || []);
        setError(null);
      } catch (err) {
        setYearPrayerTimesData([]);
        setError(
          "Kunne ikke hente bønnetider for den valgte lokasjonen og tabellen.",
        );
      } finally {
        setIsCsvLoading(false);
      }
    }
  };

  const locationOptions = locations.map((location) => ({
    value: location.id,
    label: location.name,
  }));

  const customFilter = createFilter({
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const renderPrayerTimes = () => (
    <div className={styles.tableContainer}>
      <table className={styles.prayerTimesTable}>
        <thead>
          <tr>
            <th>Dato</th>
            <th>Fajr</th>
            <th>Fajr Slutt</th>
            <th>Duhr</th>
            <th>Asr</th>
            <th>Asr1x</th>
            <th>Asr2x</th>
            <th>Maghrib</th>
            <th>Isha</th>
          </tr>
        </thead>
        <tbody>
          {prayerTimesData.map((times) => (
            <tr key={times.date}>
              <td>
                {new Intl.DateTimeFormat("default", {
                  day: "numeric",
                  month: "long",
                }).format(new Date(times.date))}
              </td>
              <td>{times.fajr}</td>
              <td>{times.fajr_endtime}</td>
              <td>{times.duhr}</td>
              <td>{times.asr}</td>
              <td>{times.asr_1x_shadow}</td>
              <td>{times.asr_2x_shadow}</td>
              <td>{times.maghrib}</td>
              <td>{times.isha}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className={`container ${styles.prayerTimesPage}`}>
      <Header title="Mawaqeet Bønnetider" />
      <div className="d-flex align-items-center justify-content-end mb-3 pt-2">
        {selectedLocationId && currentYear && currentMonth && (
          <>
            {isCsvLoading ? (
              <button className="btn btn-primary" disabled>
                Laster ned...
              </button>
            ) : yearPrayerTimesData.length > 0 ? (
              <CSVLink
                data={formatCsvData(yearPrayerTimesData)}
                filename={`prayer_times_${selectedLocationId}_${currentYear}.csv`}
                className="btn btn-primary"
              >
                Eksporter til CSV
              </CSVLink>
            ) : (
              <button className="btn btn-primary" disabled>
                Ingen data å eksportere
              </button>
            )}

            <button
              className="btn btn-secondary ms-2"
              onClick={triggerFileInput}
              disabled={isUploading}
            >
              {isUploading ? "Laster opp..." : "Importer CSV"}
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".csv"
              onChange={handleFileUpload}
            />
          </>
        )}
      </div>

      <div className="row g-3 mb-4">
        <div className="col-md-6">
          <label htmlFor="locationSearch" className="form-label">
            Velg Lokasjon
          </label>
          <Select
            options={locationOptions}
            onChange={handleLocationChange}
            placeholder="Søk og velg lokasjon"
            filterOption={customFilter}
            noOptionsMessage={() => "Ingen lokasjoner funnet"}
            isClearable
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="monthSelect" className="form-label">
            Velg Måned
          </label>
          <select
            id="monthSelect"
            className="form-select"
            value={currentMonth.getMonth()}
            onChange={(e) =>
              setCurrentMonth((prev) => {
                const newMonth = new Date(prev);
                newMonth.setMonth(parseInt(e.target.value));
                return newMonth;
              })
            }
          >
            {Array.from({ length: 12 }, (_, index) => (
              <option key={index} value={index}>
                {new Intl.DateTimeFormat("default", {
                  month: "long",
                }).format(new Date(0, index))}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <label htmlFor="yearSelect" className="form-label">
            Velg År
          </label>
          <select
            id="yearSelect"
            className="form-select"
            value={currentYear}
            onChange={(e) => setCurrentYear(parseInt(e.target.value))}
          >
            {Array.from({ length: 7 }, (_, index) => {
              const year = new Date().getFullYear() + index;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {isLoading ? (
        <PrayerTimesSkeleton />
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : selectedLocationId && currentYear && currentMonth ? (
        prayerTimesData.length > 0 ? (
          renderPrayerTimes()
        ) : (
          <Alert variant="warning">
            Ingen tilgjengelige bønnetider for den valgte perioden.
          </Alert>
        )
      ) : (
        <Alert variant="info">
          Velg en lokasjon, måned, og år for å vise bønnetidene.
        </Alert>
      )}
    </div>
  );
};

export default MawaqeetPrayerTimes;
