import {
  faEdit,
  faTrash,
  faInbox,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "AuthProvider";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import useFetch from "hooks/useFetch";
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import * as Yup from "yup";
import styles from "./PosterPage.module.scss";
import NoContentPlaceholder from "stories/NoContentPlaceholder/NoContentPlaceholder";

interface PosterForm {
  tittel: string;
  tekst: string;
  plakat: File | null;
  activated: boolean;
}

interface Poster {
  id: number;
  date: string;
  tittel: string;
  tekst: string;
  plakat: string;
  mosqueOrgNr: string;
  activated: boolean;
}

const validationSchema = Yup.object().shape({
  tittel: Yup.string().required("Tittel er påkrevd"),
  tekst: Yup.string().required("Tekst er påkrevd"),
});

const PosterPage: React.FC = () => {
  const { selectedOrgNumber } = useAuth();
  const { data, isLoading, error, execute } = useFetch<any>();
  const {
    data: posterData,
    isLoading: isPostersLoading,
    execute: fetchPosters,
  } = useFetch<Poster[]>();
  const { execute: updatePoster } = useFetch<any>();

  const [posters, setPosters] = useState<Poster[]>([]);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [editImagePreview, setEditImagePreview] = useState<string | null>(null);
  const [selectedPoster, setSelectedPoster] = useState<Poster | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [posterToDelete, setPosterToDelete] = useState<Poster | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editingPoster, setEditingPoster] = useState<Poster | null>(null);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchPosters(
        `/api/v1/poster/org/${selectedOrgNumber}`,
        "GET",
      );
      if (response) setPosters(response);
    };
    fetchData();
  }, [selectedOrgNumber]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleEditFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    if (file) {
      setEditImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (values: PosterForm, { resetForm }: any) => {
    const currentTime = new Date();
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

    const posterRequestDto = {
      date: formattedDateTime,
      tittel: values.tittel,
      tekst: values.tekst,
      mosqueOrgNr: selectedOrgNumber,
      activated: values.activated,
    };

    const formData = new FormData();
    if (values.plakat) formData.append("file", values.plakat);
    formData.append("posterRequestDto", JSON.stringify(posterRequestDto));

    try {
      const response = await execute(
        "/api/v1/poster/addWithImage",
        "POST",
        formData,
      );
      if (response) {
        setPosters((prevPosters) => [...prevPosters, response]);
        resetForm();
        setImagePreview(null);
        if (fileInputRef.current) fileInputRef.current.value = "";
      }
    } catch (error: any) {
      console.error("Error:", error.response || error.message || error);
    }
  };

  const handlePosterClick = (poster: Poster) => {
    setSelectedPoster(poster);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPoster(null);
    setEditingPoster(null);
    setEditImagePreview(null);
  };

  const confirmDeletePoster = (poster: Poster) => {
    setPosterToDelete(poster);
    setShowDeleteModal(true);
  };

  const handleDeletePoster = async () => {
    if (!posterToDelete) return;

    try {
      await execute(`/api/v1/poster/${posterToDelete.id}`, "DELETE");
      setPosters((prevPosters) =>
        prevPosters.filter((poster) => poster.id !== posterToDelete.id),
      );
      setShowDeleteModal(false);
      setPosterToDelete(null);
    } catch (error: any) {
      console.error("Failed to delete poster", error);
    }
  };

  const handleEditPoster = (poster: Poster) => {
    setEditingPoster(poster);
    setShowModal(true);
    setEditImagePreview(poster.plakat);
  };

  const handleSaveEdit = async (values: PosterForm) => {
    if (!editingPoster) return;

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

    const posterRequestDto = {
      date: formattedDateTime,
      tittel: values.tittel,
      tekst: values.tekst,
      mosqueOrgNr: selectedOrgNumber,
      activated: values.activated,
    };

    const formData = new FormData();
    formData.append("posterRequestDto", JSON.stringify(posterRequestDto));
    if (values.plakat) formData.append("file", values.plakat);

    try {
      await updatePoster(
        `/api/v1/poster/${selectedOrgNumber}/${editingPoster.id}`,
        "PATCH",
        formData,
      );
      setPosters((prev) =>
        prev.map((poster) =>
          poster.id === editingPoster.id
            ? {
                ...poster,
                tittel: values.tittel,
                tekst: values.tekst,
                activated: values.activated,
                plakat: editImagePreview || poster.plakat,
              }
            : poster,
        ),
      );
      setShowModal(false);
      setEditingPoster(null);
      setEditImagePreview(null);
    } catch (error) {
      console.error("Failed to update poster", error);
    }
  };

  return (
    <div className={styles.posterPageContainer}>
      <Card className={styles.posterCard}>
        <Card.Body>
          <h2 className="text-center">Opprett Ny Plakat</h2>
          <Formik
            initialValues={{
              tittel: "",
              tekst: "",
              plakat: null,
              activated: true,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty, setFieldValue, values }) => (
              <FormikForm>
                <Form.Group controlId="formTittel" className={styles.formGroup}>
                  <Form.Label>Tittel*</Form.Label>
                  <Field
                    name="tittel"
                    className="form-control"
                    placeholder="Skriv plakat tittel"
                  />
                  <ErrorMessage
                    name="tittel"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group controlId="formTekst" className={styles.formGroup}>
                  <Form.Label>Tekst*</Form.Label>
                  <Field
                    name="tekst"
                    as="textarea"
                    className="form-control"
                    rows={5}
                    placeholder="Skriv plakat tekst"
                  />
                  <ErrorMessage
                    name="tekst"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group controlId="formPlakat" className={styles.formGroup}>
                  <Form.Label>Plakat Bilde*</Form.Label>
                  <input
                    name="plakat"
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onChange={(event) => {
                      setFieldValue(
                        "plakat",
                        event.currentTarget.files?.[0] || null,
                      );
                      handleFileChange(event);
                    }}
                    ref={fileInputRef}
                  />
                  {imagePreview && (
                    <div className={styles.imagePreview}>
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className="img-fluid"
                      />
                    </div>
                  )}
                </Form.Group>
                <Form.Group controlId="formActivated">
                  <Form.Check
                    type="switch"
                    label="Vis dette i appen"
                    name="activated"
                    checked={values.activated}
                    onChange={(e) =>
                      setFieldValue("activated", e.target.checked)
                    }
                  />
                </Form.Group>
                {isLoading ? (
                  <Button variant="primary" disabled>
                    Laster opp...
                  </Button>
                ) : (
                  <Button
                    variant="dark"
                    type="submit"
                    className={styles.largeButton}
                    disabled={!(isValid && dirty)}
                  >
                    Lagre Plakat
                  </Button>
                )}
                {error && (
                  <Alert variant="danger" className="mt-3">
                    Error: {error.text}
                  </Alert>
                )}
                {data && (
                  <Alert variant="success" className="mt-3">
                    Plakat opprettet!
                  </Alert>
                )}
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <div className="mt-5">
        <h3>Alle Plakater</h3>
        {isPostersLoading ? (
          <Spinner animation="border" />
        ) : error ? (
          <Alert variant="danger">
            Klarte ikke å laste plakater: {error.text}
          </Alert>
        ) : posters.length > 0 ? (
          <Row className="poster-list">
            {posters.map((poster) => (
              <Col key={poster.id} sm={12} md={4} className="mb-4">
                <Card>
                  <Card.Img
                    variant="top"
                    src={poster.plakat}
                    alt={poster.tittel}
                    style={{ height: "200px", objectFit: "cover" }}
                  />
                  <Card.Body className="d-flex justify-content-between align-items-center">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {poster.activated
                              ? "Vises i appen"
                              : "Skjult i appen"}
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon={poster.activated ? faEye : faEyeSlash}
                          style={{
                            marginRight: "8px",
                          }}
                          title={
                            poster.activated
                              ? "Vises i appen"
                              : "Skjult i appen"
                          }
                        />
                      </OverlayTrigger>
                      <Card.Title className="m-auto">
                        {poster.tittel}
                      </Card.Title>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => handleEditPoster(poster)}
                        className={styles.editIcon}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => confirmDeletePoster(poster)}
                        className={styles.deleteIcon}
                      />
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">
                      Postet: {new Date(poster.date).toLocaleDateString()}
                    </small>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <NoContentPlaceholder message="Ingen plakater funnet" />
        )}
      </div>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Rediger Plakat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingPoster && (
            <Formik
              initialValues={{
                tittel: editingPoster.tittel,
                tekst: editingPoster.tekst,
                activated: editingPoster.activated,
                plakat: null,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSaveEdit}
            >
              {({ isValid, dirty, values, setFieldValue }) => (
                <FormikForm>
                  <Form.Group
                    controlId="formTittel"
                    className={styles.formGroup}
                  >
                    <Form.Label>Tittel*</Form.Label>
                    <Field
                      name="tittel"
                      className="form-control"
                      placeholder="Rediger plakat tittel"
                    />
                    <ErrorMessage
                      name="tittel"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formTekst"
                    className={styles.formGroup}
                  >
                    <Form.Label>Tekst*</Form.Label>
                    <Field
                      name="tekst"
                      as="textarea"
                      className="form-control"
                      rows={5}
                      placeholder="Rediger plakat tekst"
                    />
                    <ErrorMessage
                      name="tekst"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                  <Form.Group controlId="formActivated">
                    <Form.Check
                      type="switch"
                      label="Vis dette i appen"
                      name="activated"
                      checked={values.activated}
                      onChange={(e) =>
                        setFieldValue("activated", e.target.checked)
                      }
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="editImage"
                    className={styles.formGroup}
                  >
                    <Form.Label>Endre Bilde</Form.Label>
                    <input
                      name="plakat"
                      type="file"
                      accept="image/*"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue(
                          "plakat",
                          event.currentTarget.files?.[0] || null,
                        );
                        handleEditFileChange(event);
                      }}
                    />
                    {editImagePreview && (
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={editImagePreview}
                          alt="Edit Preview"
                          className="img-fluid"
                          style={{
                            maxHeight: "50vh",
                            maxWidth: "100%",
                            paddingTop: "10px",
                          }}
                        />
                      </div>
                    )}
                  </Form.Group>
                  <Button
                    variant="dark"
                    type="submit"
                    className={styles.largeButton}
                    disabled={!isValid}
                  >
                    Lagre Endringer
                  </Button>
                </FormikForm>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Bekreft Sletting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Er du sikker på at du vil slette plakaten med tittel:{" "}
          <strong>{posterToDelete?.tittel}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Avbryt
          </Button>
          <Button variant="danger" onClick={handleDeletePoster}>
            Slett
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PosterPage;
