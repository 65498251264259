import React, { useEffect, useState } from "react";
import { Button, Card, Form, Badge, Modal, Pagination } from "react-bootstrap";
import useFetch from "hooks/useFetch";
import Select, { createFilter } from "react-select";

interface User {
  uid: string;
  email: string;
  username: string;
  allowedOperations: string | null;
  cached: boolean;
}

interface Mosque {
  orgNr: string;
  orgName: string;
  name?: string;
}

interface MosqueOption {
  value: string;
  label: string;
  orgName: string;
  name?: string;
}

const superAdminCode = "vdfJ3fd32NibBHJ4732JNFAQCMCm38qcm34c49m";

const FilterAllowedUsersButton: React.FC<{
  users: User[];
  setFilteredUsers: React.Dispatch<React.SetStateAction<User[]>>;
  searchTerm: string;
  selectedMosqueFilter: string | null;
}> = ({ users, setFilteredUsers, searchTerm, selectedMosqueFilter }) => {
  const [showOnlyAdmins, setShowOnlyAdmins] = useState(false);

  const applyFilters = (userList: User[]) => {
    const lowerSearchTerm = searchTerm.toLowerCase();

    return userList.filter((user) => {
      const isSuperAdmin = user.allowedOperations === superAdminCode;
      const matchesSearchTerm =
        user.email.toLowerCase().includes(lowerSearchTerm) ||
        user.username.toLowerCase().includes(lowerSearchTerm) ||
        (user.allowedOperations &&
          user.allowedOperations.toLowerCase().includes(lowerSearchTerm)) ||
        (lowerSearchTerm === "super admin" && isSuperAdmin);

      const matchesMosqueFilter =
        !selectedMosqueFilter ||
        user.allowedOperations === selectedMosqueFilter;

      return matchesSearchTerm && matchesMosqueFilter;
    });
  };

  useEffect(() => {
    setShowOnlyAdmins(false);
  }, [searchTerm, selectedMosqueFilter]);

  const handleToggle = () => {
    if (!showOnlyAdmins) {
      const admins = users.filter((user) => user.allowedOperations !== null);
      setFilteredUsers(applyFilters(admins));
    } else {
      setFilteredUsers(applyFilters(users));
    }
    setShowOnlyAdmins(!showOnlyAdmins);
  };

  return (
    <Button onClick={handleToggle} variant="info" className="mb-4">
      {showOnlyAdmins ? "Vis alle brukere" : "Vis admin brukere"}
    </Button>
  );
};

const UsersPage: React.FC = () => {
  const { execute } = useFetch();
  const [users, setUsers] = useState<User[]>([]);
  const [mosques, setMosques] = useState<Mosque[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedMosqueOrgNr, setSelectedMosqueOrgNr] = useState("");
  const [selectedMosqueFilter, setSelectedMosqueFilter] = useState<
    string | null
  >(null);
  const [showRemoveRightsModal, setShowRemoveRightsModal] = useState(false);
  const [showSuperAdminModal, setShowSuperAdminModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const mosqueOptions: MosqueOption[] = mosques.map((mosque) => ({
    value: mosque.orgNr,
    label: (mosque.name || mosque.orgName)?.toUpperCase(),
    orgName: mosque.orgName,
  }));

  const customFilter = createFilter({
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const superAdminCode = "vdfJ3fd32NibBHJ4732JNFAQCMCm38qcm34c49m";

  const fetchUsers = async () => {
    const usersResult = (await execute("/api/v1/users", "GET")) as User[];
    if (usersResult) setUsers(usersResult);
  };

  const fetchMosques = async () => {
    const mosquesResult = (await execute("/api/v1/mosques", "GET")) as Mosque[];
    if (mosquesResult) {
      const sortedMosques = mosquesResult.sort((a, b) =>
        a.orgName.localeCompare(b.orgName),
      );
      setMosques(sortedMosques);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchMosques();
  }, []);

  useEffect(() => {
    const filtered = users.filter((user) => {
      const isSuperAdmin = user.allowedOperations === superAdminCode;
      const lowerSearchTerm = searchTerm.toLowerCase();

      const matchesSearchTerm =
        user.email.toLowerCase().includes(lowerSearchTerm) ||
        user.username.toLowerCase().includes(lowerSearchTerm) ||
        (user.allowedOperations &&
          user.allowedOperations.toLowerCase().includes(lowerSearchTerm)) ||
        (lowerSearchTerm === "super admin" && isSuperAdmin);

      const matchesMosqueFilter =
        !selectedMosqueFilter ||
        user.allowedOperations === selectedMosqueFilter;

      return matchesSearchTerm && matchesMosqueFilter;
    });

    setFilteredUsers(filtered);
    setCurrentPage(1);
  }, [searchTerm, selectedMosqueFilter, users]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredUsers]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleAdminToggleClick = (user: User) => {
    setSelectedUser(user);
    setSelectedMosqueOrgNr(user.allowedOperations || "");
    setShowModal(true);
  };

  const handleSelectChange = (newValue: unknown) => {
    const selectedOption = newValue as MosqueOption;
    setSelectedMosqueOrgNr(selectedOption ? selectedOption.value : "");
  };

  const handleRemoveRightsClick = (user: User) => {
    setSelectedUser(user);
    setShowRemoveRightsModal(true);
  };

  const handleRemoveRightsConfirm = async () => {
    if (selectedUser) {
      try {
        await execute(`/api/v1/users/${selectedUser.uid}/delete`, "PATCH");

        setUsers((prevUsers) =>
          prevUsers.map((u) =>
            u.uid === selectedUser.uid ? { ...u, allowedOperations: null } : u,
          ),
        );

        setShowRemoveRightsModal(false);
        setSelectedUser(null);
      } catch (error) {
        console.error("En feil oppstod ved fjerning av rettigheter:", error);
      }
    }
  };

  const handleSuperAdminClick = (user: User) => {
    setSelectedUser(user);
    setShowSuperAdminModal(true);
  };

  const handleAdminToggleConfirm = async () => {
    if (selectedUser && selectedMosqueOrgNr) {
      await execute(
        `/api/v1/users/${selectedUser.uid}/${selectedMosqueOrgNr}`,
        "PATCH",
      );

      const updatedUser = {
        ...selectedUser,
        allowedOperations: selectedMosqueOrgNr,
      };

      setUsers((prevUsers) =>
        prevUsers.map((u) => (u.uid === selectedUser.uid ? updatedUser : u)),
      );

      setShowModal(false);
      setSelectedUser(null);
    }
  };

  const handleSuperAdminConfirm = async () => {
    if (selectedUser) {
      await execute(
        `/api/v1/users/${selectedUser.uid}/${superAdminCode}`,
        "PATCH",
      );

      const updatedUser = {
        ...selectedUser,
        allowedOperations: "Super Admin",
      };

      setUsers((prevUsers) =>
        prevUsers.map((u) => (u.uid === selectedUser.uid ? updatedUser : u)),
      );

      setShowSuperAdminModal(false);
      setSelectedUser(null);
    }
  };

  const handleDeleteClick = (user: User) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedUser) {
      await execute(`/api/v1/users/${selectedUser.uid}`, "DELETE");

      setUsers((prevUsers) =>
        prevUsers.filter((u) => u.uid !== selectedUser.uid),
      );

      setShowDeleteModal(false);
      setSelectedUser(null);
    }
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
    const maxVisiblePages = 7;
    const pages: (number | "ellipsis")[] = [];

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const rangeStart = Math.max(2, currentPage - 3);
      const rangeEnd = Math.min(totalPages - 1, currentPage + 3);

      pages.push(1);

      if (rangeStart > 2) {
        pages.push("ellipsis");
      }

      for (let i = rangeStart; i <= rangeEnd; i++) {
        pages.push(i);
      }

      if (rangeEnd < totalPages - 1) {
        pages.push("ellipsis");
      }

      pages.push(totalPages);
    }

    return (
      <Pagination>
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => paginate(currentPage - 1)}
        >
          Forrige
        </Pagination.Prev>
        {pages.map((page, index) =>
          page === "ellipsis" ? (
            <Pagination.Ellipsis key={`ellipsis-${index}`} />
          ) : (
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => paginate(page)}
            >
              {page}
            </Pagination.Item>
          ),
        )}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => paginate(currentPage + 1)}
        >
          Neste
        </Pagination.Next>
      </Pagination>
    );
  };

  return (
    <div className="container mt-4">
      <h1>Administrer brukere</h1>

      <div className="filters mb-4">
        <Form.Control
          type="text"
          placeholder="Søk etter e-post, brukernavn eller tillatte operasjoner..."
          className="mb-2"
          style={{ border: "1px solid #CCCCCC", boxShadow: "none" }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Select
          options={mosqueOptions}
          onChange={(option) =>
            setSelectedMosqueFilter(
              option ? (option as MosqueOption).value : null,
            )
          }
          placeholder="Filtrer admin etter Moské"
          filterOption={customFilter}
          isClearable
          className="mb-4"
          noOptionsMessage={() => "Ingen moskèer funnet"}
        />
        <FilterAllowedUsersButton
          users={users}
          setFilteredUsers={setFilteredUsers}
          searchTerm={searchTerm}
          selectedMosqueFilter={selectedMosqueFilter}
        />
      </div>

      {/* User List */}
      <div className="user-list">
        {currentItems.map((user) => (
          <Card key={user.uid} className="mb-3">
            <Card.Header>
              <strong>{user.email}</strong>{" "}
              {user.allowedOperations === superAdminCode && (
                <Badge bg="success" className="ms-2">
                  Super Admin
                </Badge>
              )}
            </Card.Header>
            <Card.Body>
              <p>
                <strong>Brukernavn:</strong> {user.username}
              </p>
              <p>
                <strong>Tillatte operasjoner:</strong>{" "}
                {user.allowedOperations === superAdminCode
                  ? "Super Admin"
                  : user.allowedOperations
                    ? mosques.find(
                        (mosque) => mosque.orgNr === user.allowedOperations,
                      )?.orgName || user.allowedOperations
                    : "None"}
              </p>
              <Button
                variant="primary"
                onClick={() => handleAdminToggleClick(user)}
                className="me-2"
              >
                Oppgrader Admin
              </Button>
              <Button
                variant="success"
                onClick={() => handleSuperAdminClick(user)}
                className="me-2"
              >
                Oppgrader Super Admin
              </Button>
              <Button
                variant="danger"
                onClick={() => handleRemoveRightsClick(user)}
                className="me-2"
              >
                Fjern rettigheter
              </Button>
              <Button variant="danger" onClick={() => handleDeleteClick(user)}>
                Slett bruker
              </Button>
            </Card.Body>
          </Card>
        ))}
      </div>

      {/* Pagination */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "20px",
          gap: "4px",
        }}
      >
        {renderPagination()}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Angi admin rettigheter for {selectedUser?.email}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Velg en moské for å angi admin rettigheter for denne brukeren:</p>
          <Select
            options={mosqueOptions}
            onChange={handleSelectChange}
            placeholder="Søk og velg Moské"
            filterOption={customFilter}
            noOptionsMessage={() => "Ingen moskéer funnet"}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Avbryt
          </Button>
          <Button
            variant="primary"
            onClick={handleAdminToggleConfirm}
            disabled={!selectedMosqueOrgNr}
          >
            Bekreft
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Remove Rights Confirmation Modal */}
      <Modal
        show={showRemoveRightsModal}
        onHide={() => setShowRemoveRightsModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Fjern admin rettigheter for {selectedUser?.email}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Er du sikker på at du vil fjerne admin rettigheter for{" "}
          <strong>{selectedUser?.email}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowRemoveRightsModal(false)}
          >
            Avbryt
          </Button>
          <Button variant="danger" onClick={handleRemoveRightsConfirm}>
            Bekreft
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirm Super Admin Modal */}
      <Modal
        show={showSuperAdminModal}
        onHide={() => setShowSuperAdminModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Angi Super Admin-tilgang</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Er du sikker på at du vil gjøre <strong>{selectedUser?.email}</strong>{" "}
          til Super Admin?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSuperAdminModal(false)}
          >
            Avbryt
          </Button>
          <Button variant="success" onClick={handleSuperAdminConfirm}>
            Bekreft
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Slett bruker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Er du sikker på at du vil slette brukeren{" "}
          <strong>{selectedUser?.email}</strong> permanent?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Avbryt
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Slett
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UsersPage;
