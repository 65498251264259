import React from "react";
import styles from "./sideMenu.module.scss";

interface SideMenuProps {
  children: React.ReactNode;
}

const SideMenu: React.FC<SideMenuProps> = ({ children }) => {
  return (
    <div className={styles.sideMenu} data-testid="side-menu">
      {children}
    </div>
  );
};

export default SideMenu;
