// MosqueSelection.tsx
import { useAuth } from "AuthProvider";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select, { createFilter } from "react-select";
import { Button } from "stories";
import { ReactComponent as Icon } from "../../assets/ibonn-icon.svg";
import styles from "./SuperAdminMosqueSelection.module.scss";
import { RoutePaths } from "enums/RoutePaths";

interface Mosque {
  orgNr: string;
  orgName: string;
  name?: string;
}

const SuperAdminMosqueSelection: React.FC = () => {
  const {
    setSelectedOrgNumber,
    setMosqueLogo,
    setMosqueName,
    setSuperAdminMode,
    setSelectedOrgName,
  } = useAuth();
  const { execute } = useFetch<Mosque[]>();
  const [mosqueList, setMosqueList] = useState<Mosque[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMosques = async () => {
      const url = `/api/v1/mosques`;
      const result = await execute(url, "GET");
      if (result) {
        const sortedMosques = result.sort((a: Mosque, b: Mosque) =>
          a.orgName.localeCompare(b.orgName),
        );
        setMosqueList(sortedMosques);
      }
    };
    fetchMosques();
  }, []);

  const handleSelectChange = (selectedOption: any) => {
    setMosqueName("");
    setMosqueLogo("");
    sessionStorage.removeItem("mosqueName");
    sessionStorage.removeItem("mosqueLogo");

    if (selectedOption) {
      const selectedOrgNr = selectedOption.value;
      setSelectedOrgNumber(selectedOrgNr);
      setSelectedOrgName(selectedOption.orgName);
      sessionStorage.setItem("selectedOrgNumber", selectedOrgNr);
      sessionStorage.setItem("selectedOrgName", selectedOption.orgName);
      navigate("/");
    }
  };

  const handleSuperAdminMode = () => {
    setSuperAdminMode(true);
    sessionStorage.setItem("isSuperAdminMode", "true");
    navigate(RoutePaths.MosqueAdmin);
  };

  const mosqueOptions = mosqueList.map((mosque) => ({
    value: mosque.orgNr,
    label: (mosque.name || mosque.orgName)?.toUpperCase(),
    orgName: mosque.orgName,
    name: mosque.name,
  }));

  const customFilter = createFilter({
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: "any",
    stringify: (option) => {
      const data = option.data as Mosque;
      return `${data.orgName} ${data.name}`;
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <Icon />
          <h2 className={styles.heading}>Vennligst velg en moskè</h2>
          <Select
            options={mosqueOptions}
            onChange={handleSelectChange}
            placeholder="Søk og velg Moské"
            filterOption={customFilter}
            noOptionsMessage={() => "Ingen moskèer funnet"}
          />
        </div>
        <div>
          <h4>...eller gå til super admin</h4>
        </div>
        <div>
          <Button
            onClick={handleSuperAdminMode}
            label="Gå til Super Admin"
            variant="secondary"
            size="large"
          />
        </div>
      </div>
    </div>
  );
};

export default SuperAdminMosqueSelection;
