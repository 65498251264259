import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

interface LoadingProps {
  size?: "small" | "medium" | "large";
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  message?: string;
}

const Loading: React.FC<LoadingProps> = ({
  size = "medium",
  variant = "primary",
  message,
}) => {
  const spinnerSizeClass =
    size === "small"
      ? "spinner-border-sm"
      : size === "large"
        ? "spinner-border-lg"
        : "";

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div
        className={`spinner-border text-${variant} ${spinnerSizeClass}`}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
      {message && <span className="ms-2">{message}</span>}
    </div>
  );
};

export default Loading;
