import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button } from "stories";
import OffsetModal from "./OffsetModal/OffsetModal";
import styles from "./PrayerTimeRow.module.scss";

interface PrayerTimes {
  id?: number;
  fajr: string;
  duhr: string;
  asr: string;
  maghrib: string;
  isha: string;
  fajrJamatOffset?: number;
  duhrJamatOffset?: number;
  asrJamatOffset?: number;
  maghribJamatOffset?: number;
  ishaJamatOffset?: number;
}

interface PrayerTimeRowProps {
  dateKey: string;
  times: PrayerTimes;
  formattedDate: string;
  previousTimes?: PrayerTimes | null;
  onPrayerTimeChange: (
    dateKey: string,
    prayer: "fajr" | "duhr" | "asr" | "maghrib" | "isha",
    time: string,
  ) => void;
  onOffsetChange: (
    dateKey: string,
    offset:
      | "fajrJamatOffset"
      | "duhrJamatOffset"
      | "asrJamatOffset"
      | "maghribJamatOffset"
      | "ishaJamatOffset",
    value: number,
  ) => void;
  onSave: (dateKey: string) => void;
  onCreate: (dateKey: string, times: PrayerTimes) => void;
  onDelete: (dateKey: string) => void;
  fetchLatestPrayerTimes: () => void;
}

const PrayerTimeRow: React.FC<PrayerTimeRowProps> = ({
  dateKey,
  times,
  formattedDate,
  previousTimes,
  onPrayerTimeChange,
  onOffsetChange,
  onSave,
  onCreate,
  onDelete,
  fetchLatestPrayerTimes,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFormActive, setFormActive] = useState(false);

  const hasValidTimes = () => {
    return (
      (times.fajr !== "" && times.fajr !== "00:00") ||
      (times.duhr !== "" && times.duhr !== "00:00") ||
      (times.asr !== "" && times.asr !== "00:00") ||
      (times.maghrib !== "" && times.maghrib !== "00:00") ||
      (times.isha !== "" && times.isha !== "00:00")
    );
  };

  const handleActivateForm = () => {
    setFormActive(true);
  };

  const handleSave = () => {
    if (times.id !== undefined) {
      onSave(dateKey);
    } else {
      onCreate(dateKey, times);
    }
  };

  const handleDelete = () => {
    if (times.id !== undefined) {
      onDelete(dateKey);
    } else {
      console.error("Cannot delete prayer time because id is missing.");
    }
  };

  const handleOffsetClick = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleCopyFromAbove = () => {
    if (!previousTimes) {
      console.warn("Ingen tidligere tider tilgjengelig for kopiering.");
      return;
    }

    const prayers: Array<"fajr" | "duhr" | "asr" | "maghrib" | "isha"> = [
      "fajr",
      "duhr",
      "asr",
      "maghrib",
      "isha",
    ];
    prayers.forEach((prayer) => {
      const time = previousTimes[prayer];
      onPrayerTimeChange(
        dateKey,
        prayer,
        time && time !== "00:00" ? time : "00:00",
      );
    });

    const offsets: Array<
      | "fajrJamatOffset"
      | "duhrJamatOffset"
      | "asrJamatOffset"
      | "maghribJamatOffset"
      | "ishaJamatOffset"
    > = [
      "fajrJamatOffset",
      "duhrJamatOffset",
      "asrJamatOffset",
      "maghribJamatOffset",
      "ishaJamatOffset",
    ];
    offsets.forEach((offset) => {
      const offsetValue = previousTimes[offset];
      onOffsetChange(dateKey, offset, offsetValue != null ? offsetValue : -1);
    });
  };

  const renderOffsetText = (
    offset: number | undefined,
    isDisabled: boolean,
  ) => {
    if (!isDisabled || offset === undefined || offset === -1) {
      return null;
    }
    return <em className={styles.offsetText}>+{offset}</em>;
  };

  const isInputDisabled = (time: string, offset?: number) => {
    return (
      time !== "" && time !== "00:00" && offset !== undefined && offset !== -1
    );
  };

  const handleTimeChange = (
    prayer: "fajr" | "duhr" | "asr" | "maghrib" | "isha",
    value: string,
  ) => {
    const cleanedValue = value.replace(/[^\d:]/g, "");

    if (cleanedValue.length === 2 && !cleanedValue.includes(":")) {
      onPrayerTimeChange(dateKey, prayer, cleanedValue + ":");
    } else if (/^(\d{0,2}):?(\d{0,2})$/.test(cleanedValue)) {
      onPrayerTimeChange(dateKey, prayer, cleanedValue);
    }
  };

  const handleTimeBlur = (
    prayer: "fajr" | "duhr" | "asr" | "maghrib" | "isha",
    value: string,
  ) => {
    if (value === "") {
      onPrayerTimeChange(dateKey, prayer, "");
    } else if (!/^([01]\d|2[0-3]):([0-5]\d)$/.test(value)) {
      onPrayerTimeChange(dateKey, prayer, "00:00");
    }
  };

  const isFirstDayOfMonth = dateKey.startsWith("01");

  return (
    <div className={styles.prayerRow}>
      <div className={`row ${styles.row}`}>
        <div className={styles.date}>{formattedDate}</div>
        <div className={styles.actions}>
          {!isFormActive && !hasValidTimes() ? (
            <Button
              label="Legg til jamat tider"
              size="large"
              onClick={handleActivateForm}
            />
          ) : (
            <>
              <Button
                variant="info"
                label="Bruk offset"
                size="large"
                onClick={handleOffsetClick}
              />
              {!isFirstDayOfMonth && (
                <Button
                  variant="primary"
                  label="Kopier fra forrige dag"
                  size="large"
                  onClick={handleCopyFromAbove}
                  disabled={!previousTimes}
                />
              )}
              {/*
              <Button
                label="Lagre"
                size="large"
                onClick={handleSave}
                disabled={
                  times.id === 0 ||
                  (times.fajr === "00:00" &&
                    times.duhr === "00:00" &&
                    times.asr === "00:00" &&
                    times.maghrib === "00:00" &&
                    times.isha === "00:00")
                }
              />
              */}
              <Button
                variant="error"
                size="large"
                onClick={handleDelete}
                icon={<FontAwesomeIcon icon={faTrash} />}
                disabled={times.id === 0}
              />
            </>
          )}
        </div>
      </div>
      {(isFormActive || hasValidTimes()) && (
        <div className={`row ${styles.row}`}>
          <div className={styles.prayerTimes}>
            <div className={styles.prayerTime}>
              <label className={styles.prayerLabel}>Fajr</label>
              <input
                type="text"
                className="form-control"
                value={
                  times.fajr === "00:00" || times.fajr === "" ? "" : times.fajr
                }
                onChange={(e) => handleTimeChange("fajr", e.target.value)}
                onBlur={(e) => handleTimeBlur("fajr", e.target.value)}
                disabled={isInputDisabled(times.fajr, times.fajrJamatOffset)}
                placeholder="HH:MM"
              />
              {renderOffsetText(
                times.fajrJamatOffset,
                isInputDisabled(times.fajr, times.fajrJamatOffset),
              )}
            </div>

            <div className={styles.prayerTime}>
              <label className={styles.prayerLabel}>Dhuhr</label>
              <input
                type="text"
                className="form-control"
                value={
                  times.duhr === "00:00" || times.duhr === "" ? "" : times.duhr
                }
                onChange={(e) => handleTimeChange("duhr", e.target.value)}
                onBlur={(e) => handleTimeBlur("duhr", e.target.value)}
                disabled={isInputDisabled(times.duhr, times.duhrJamatOffset)}
                placeholder="HH:MM"
              />
              {renderOffsetText(
                times.duhrJamatOffset,
                isInputDisabled(times.duhr, times.duhrJamatOffset),
              )}
            </div>

            <div className={styles.prayerTime}>
              <label className={styles.prayerLabel}>Asr</label>
              <input
                type="text"
                className="form-control"
                value={
                  times.asr === "00:00" || times.asr === "" ? "" : times.asr
                }
                onChange={(e) => handleTimeChange("asr", e.target.value)}
                onBlur={(e) => handleTimeBlur("asr", e.target.value)}
                disabled={isInputDisabled(times.asr, times.asrJamatOffset)}
                placeholder="HH:MM"
              />
              {renderOffsetText(
                times.asrJamatOffset,
                isInputDisabled(times.asr, times.asrJamatOffset),
              )}
            </div>

            <div className={styles.prayerTime}>
              <label className={styles.prayerLabel}>Maghrib</label>
              <input
                type="text"
                className="form-control"
                value={
                  times.maghrib === "00:00" || times.maghrib === ""
                    ? ""
                    : times.maghrib
                }
                onChange={(e) => handleTimeChange("maghrib", e.target.value)}
                onBlur={(e) => handleTimeBlur("maghrib", e.target.value)}
                disabled={isInputDisabled(
                  times.maghrib,
                  times.maghribJamatOffset,
                )}
                placeholder="HH:MM"
              />
              {renderOffsetText(
                times.maghribJamatOffset,
                isInputDisabled(times.maghrib, times.maghribJamatOffset),
              )}
            </div>

            <div className={styles.prayerTime}>
              <label className={styles.prayerLabel}>Isha</label>
              <input
                type="text"
                className="form-control"
                value={
                  times.isha === "00:00" || times.isha === "" ? "" : times.isha
                }
                onChange={(e) => handleTimeChange("isha", e.target.value)}
                onBlur={(e) => handleTimeBlur("isha", e.target.value)}
                disabled={isInputDisabled(times.isha, times.ishaJamatOffset)}
                placeholder="HH:MM"
              />
              {renderOffsetText(
                times.ishaJamatOffset,
                isInputDisabled(times.isha, times.ishaJamatOffset),
              )}
            </div>
          </div>
        </div>
      )}
      <OffsetModal
        show={isModalOpen}
        formattedDate={formattedDate}
        times={times}
        dateKey={dateKey}
        onInputChange={onOffsetChange}
        onSave={onSave}
        onClose={closeModal}
        fetchLatestPrayerTimes={fetchLatestPrayerTimes}
      />
    </div>
  );
};

export default PrayerTimeRow;
