import React from "react";
import { Table } from "react-bootstrap";
import styles from "./NotificationList.module.scss";

interface Notification {
  id: number;
  title: string;
  body: string;
  date: string;
}

interface NotificationListProps {
  notifications: Notification[];
}

const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
}) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  return (
    <div className={styles.notificationListContainer}>
      <Table
        bgcolor="white"
        striped
        bordered
        hover
        responsive
        className={styles.notificationTable}
      >
        <thead>
          <tr>
            <th>Tittel</th>
            <th>Beskrivelse</th>
            <th>Sendt dato</th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((notification) => (
            <tr key={notification.id}>
              <td>{notification.title}</td>
              <td>{notification.body}</td>
              <td>{formatDate(notification.date)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default NotificationList;
