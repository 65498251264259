import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "AuthProvider";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import styles from "./CampaignPage.module.scss";
import NoContentPlaceholder from "stories/NoContentPlaceholder/NoContentPlaceholder";

interface CampaignForm {
  name: string;
  description: string;
  targetAmount: number;
  activated: boolean;
}

interface Campaign {
  id: number;
  name: string;
  description: string;
  targetAmount: number;
  activated: boolean;
  createdAt: string;
}

const MAX_DESCRIPTION_LENGTH = 255;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Navn er påkrevd"),
  description: Yup.string()
    .required("Beskrivelse er påkrevd")
    .max(MAX_DESCRIPTION_LENGTH, `Maks ${MAX_DESCRIPTION_LENGTH} tegn tillatt`),
  targetAmount: Yup.number()
    .required("Målbeløp er påkrevd")
    .min(1, "Målbeløp må være større enn 0"),
});

const CampaignPage: React.FC = () => {
  const { orgNumber, selectedOrgNumber } = useAuth();
  const { data, isLoading, error, execute } = useFetch<any>();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [isCampaignsLoading, setIsCampaignsLoading] = useState(true);
  const [campaignCreated, setCampaignCreated] = useState(false);
  const [editingCampaign, setEditingCampaign] = useState<Campaign | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await execute(
          `/api/v1/campaigns/org/${selectedOrgNumber}`,
          "GET",
        );
        if (response) {
          setCampaigns(response);
          setIsCampaignsLoading(false);
        }
      } catch (err: any) {
        console.error("Error fetching campaigns:", err);
        setIsCampaignsLoading(false);
      }
    };

    fetchCampaigns();
  }, [selectedOrgNumber]);

  const handleSubmit = async (values: CampaignForm, { resetForm }: any) => {
    const campaignRequestDto = {
      name: values.name,
      description: values.description,
      targetAmount: values.targetAmount,
      orgNr: selectedOrgNumber,
      activated: values.activated,
    };

    try {
      const response = await execute(
        "/api/v1/campaigns",
        "POST",
        campaignRequestDto,
      );

      if (response) {
        setCampaignCreated(true);
        resetForm();
        setCharCount(0);
        setCampaigns([...campaigns, response]);
      }
    } catch (error: any) {
      console.error("Error:", error.response || error.message || error);
    }
  };

  const handleDeleteCampaign = async (id: number) => {
    try {
      await execute(`/api/v1/campaigns/${id}`, "DELETE");
      setCampaigns(campaigns.filter((campaign) => campaign.id !== id));
    } catch (error: any) {
      console.error(
        "Error deleting campaign:",
        error.response || error.message || error,
      );
    }
  };

  const handleEditCampaign = (campaign: Campaign) => {
    setEditingCampaign(campaign);
    setShowModal(true);
  };

  const handleSaveEdit = async (values: CampaignForm) => {
    if (!editingCampaign) return;

    const updatedCampaign = {
      ...editingCampaign,
      name: values.name,
      description: values.description,
      targetAmount: values.targetAmount,
      activated: values.activated,
    };

    try {
      await execute(
        `/api/v1/campaigns/${editingCampaign.id}`,
        "PATCH",
        updatedCampaign,
      );
      setCampaigns(
        campaigns.map((campaign) =>
          campaign.id === editingCampaign.id ? updatedCampaign : campaign,
        ),
      );
      setShowModal(false);
      setEditingCampaign(null);
    } catch (error: any) {
      console.error(
        "Error updating campaign:",
        error.response || error.message || error,
      );
    }
  };

  return (
    <div className={styles.campaignPageContainer}>
      <Card className={styles.campaignCard}>
        <Card.Body>
          <h2 className="text-center mb-4">Opprett Ny Kampanje</h2>

          <Formik
            initialValues={{
              name: "",
              description: "",
              targetAmount: 0,
              activated: false,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty, setFieldValue, values }) => (
              <FormikForm>
                <Form.Group controlId="formName" className={styles.formGroup}>
                  <Form.Label>Kampanje Navn*</Form.Label>
                  <Field
                    name="name"
                    className="form-control"
                    placeholder="Skriv kampanje navn"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group
                  controlId="formDescription"
                  className={styles.formGroup}
                >
                  <Form.Label>Beskrivelse*</Form.Label>
                  <Field
                    name="description"
                    as="textarea"
                    className="form-control"
                    rows={5}
                    placeholder="Skriv beskrivelse"
                    maxLength={MAX_DESCRIPTION_LENGTH}
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      const value = e.target.value.slice(
                        0,
                        MAX_DESCRIPTION_LENGTH,
                      );
                      setFieldValue("description", value);
                      setCharCount(value.length);
                    }}
                  />
                  <div className="text-muted small">
                    {charCount}/{MAX_DESCRIPTION_LENGTH} tegn
                  </div>
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group
                  controlId="formTargetAmount"
                  className={styles.formGroup}
                >
                  <Form.Label>Målbeløp*</Form.Label>
                  <Field
                    name="targetAmount"
                    type="number"
                    className="form-control"
                    placeholder="Skriv målbeløp"
                  />
                  <ErrorMessage
                    name="targetAmount"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group controlId="formActivated">
                  <Form.Check
                    type="switch"
                    label="Aktiver kampanje"
                    name="activated"
                    checked={values.activated}
                    onChange={(e) =>
                      setFieldValue("activated", e.target.checked)
                    }
                  />
                </Form.Group>

                {isLoading ? (
                  <Button variant="primary" disabled>
                    Lagrer...
                  </Button>
                ) : (
                  <Button
                    variant="dark"
                    type="submit"
                    size="lg"
                    className={styles.largeButton}
                    disabled={!(isValid && dirty)}
                  >
                    Lagre Kampanje
                  </Button>
                )}

                {error && (
                  <Alert variant="danger" className="mt-3">
                    Error: {error.text}
                  </Alert>
                )}
                {campaignCreated && (
                  <Alert variant="success" className="mt-3">
                    Kampanje opprettet!
                  </Alert>
                )}
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <h3>Alle Kampanjer</h3>
      {isCampaignsLoading ? (
        <Spinner animation="border" />
      ) : campaigns.length > 0 ? (
        <div className={styles.campaignList}>
          {campaigns.map((campaign) => (
            <Card key={campaign.id} className={styles.campaignItem}>
              <Card.Body>
                <h4>{campaign.name}</h4>
                <p>{campaign.description}</p>
                <p>
                  <strong>Målbeløp:</strong> {campaign.targetAmount} kr
                </p>
                <p>
                  <strong>Status:</strong>{" "}
                  {campaign.activated ? "Aktiv" : "Inaktiv"}
                </p>
                <div style={{ display: "flex", gap: "10px" }}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    className={styles.editIcon}
                    onClick={() => handleEditCampaign(campaign)}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => handleDeleteCampaign(campaign.id)}
                    className={styles.deleteIcon}
                  />
                </div>
              </Card.Body>
            </Card>
          ))}
        </div>
      ) : (
        <NoContentPlaceholder message="Ingen kampanjer å vise." />
      )}

      {/* Edit Campaign Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Rediger Kampanje</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingCampaign && (
            <Formik
              initialValues={{
                name: editingCampaign.name,
                description: editingCampaign.description,
                targetAmount: editingCampaign.targetAmount,
                activated: editingCampaign.activated,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSaveEdit}
            >
              {({ isValid, dirty, setFieldValue, values }) => (
                <FormikForm>
                  <Form.Group controlId="formName" className={styles.formGroup}>
                    <Form.Label>Kampanje Navn*</Form.Label>
                    <Field
                      name="name"
                      className="form-control"
                      placeholder="Skriv kampanje navn"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="formDescription"
                    className={styles.formGroup}
                  >
                    <Form.Label>Beskrivelse*</Form.Label>
                    <Field
                      name="description"
                      as="textarea"
                      className="form-control"
                      rows={5}
                      placeholder="Skriv beskrivelse"
                      maxLength={MAX_DESCRIPTION_LENGTH}
                      onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const value = e.target.value.slice(
                          0,
                          MAX_DESCRIPTION_LENGTH,
                        );
                        setFieldValue("description", value);
                        setCharCount(value.length);
                      }}
                    />
                    <div className="text-muted small">
                      {charCount}/{MAX_DESCRIPTION_LENGTH} tegn
                    </div>
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="formTargetAmount"
                    className={styles.formGroup}
                  >
                    <Form.Label>Målbeløp*</Form.Label>
                    <Field
                      name="targetAmount"
                      type="number"
                      className="form-control"
                      placeholder="Skriv målbeløp"
                    />
                    <ErrorMessage
                      name="targetAmount"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>

                  <Form.Group controlId="formActivated">
                    <Form.Check
                      type="switch"
                      label="Aktiver kampanje"
                      name="activated"
                      checked={values.activated}
                      onChange={(e) =>
                        setFieldValue("activated", e.target.checked)
                      }
                    />
                  </Form.Group>

                  <Button
                    variant="dark"
                    type="submit"
                    size="lg"
                    className={styles.largeButton}
                    disabled={!(isValid && dirty)}
                  >
                    Lagre Endringer
                  </Button>
                </FormikForm>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CampaignPage;
