import React from "react";
import styles from "./prayerTimesSkeleton.module.scss";

const PrayerTimesSkeleton: React.FC = () => {
  const renderSkeletonRows = () => {
    const skeletonRows = [];
    for (let i = 0; i < 5; i++) {
      skeletonRows.push(
        <tr key={i}>
          <td className={styles.skeletonCell}>
            <div className={styles.skeleton} />
          </td>
          <td className={styles.skeletonCell}>
            <div className={styles.skeleton} />
          </td>
          <td className={styles.skeletonCell}>
            <div className={styles.skeleton} />
          </td>
          <td className={styles.skeletonCell}>
            <div className={styles.skeleton} />
          </td>
          <td className={styles.skeletonCell}>
            <div className={styles.skeleton} />
          </td>
          <td className={styles.skeletonCell}>
            <div className={styles.skeleton} />
          </td>
        </tr>,
      );
    }
    return skeletonRows;
  };

  return (
    <table className={styles.prayerTimesTable}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Fajr</th>
          <th>Duhr</th>
          <th>Asr</th>
          <th>Maghrib</th>
          <th>Isha</th>
        </tr>
      </thead>
      <tbody>{renderSkeletonRows()}</tbody>
    </table>
  );
};

export default PrayerTimesSkeleton;
