import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import useFetch from "hooks/useFetch";
import NoContentPlaceholder from "stories/NoContentPlaceholder/NoContentPlaceholder";
import NotificationList from "./NotificationList/NotificationList";
import styles from "./MawaqeetPushPage.module.scss";

interface PushNotificationForm {
  title: string;
  imgurl: string;
  body: string;
  uid: string;
}

interface Notification {
  id: number;
  title: string;
  body: string;
  date: string;
}

const MAX_CHAR_LIMIT = 255;
const NOTIFICATIONS_PER_PAGE = 10;
// const MAWAQEET_ORGNR = "MAWAQEETADMIN6328xkeowkgj63827481";
const MAWAQEET_ORGNR = "prayertable_id_2";

const validationSchema = Yup.object().shape({
  body: Yup.string()
    .required("Melding er påkrevd")
    .max(MAX_CHAR_LIMIT, `Melding kan ikke overstige ${MAX_CHAR_LIMIT} tegn`),
});

const MawaqeetPushPage: React.FC = () => {
  const { data, isLoading, error, execute } = useFetch<any>();
  const {
    data: notificationsData,
    isLoading: isNotificationsLoading,
    execute: fetchNotifications,
  } = useFetch<any>();

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchNotifications(
        `/api/v1/notification/${MAWAQEET_ORGNR}`,
        "GET",
      );
      if (response) {
        const sortedNotifications = response.sort(
          (a: Notification, b: Notification) =>
            new Date(b.date).getTime() - new Date(a.date).getTime(),
        );
        setNotifications(sortedNotifications);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (
    values: PushNotificationForm,
    { resetForm }: any,
  ) => {
    const payload = {
      orgnr: "",
      title: "Mawaqeet",
      imgurl: "",
      body: values.body,
    };

    await execute(
      `/api/v1/notification/send-notification/prayertable-id-2`,
      "POST",
      payload,
    );

    resetForm();
    setCharCount(0);
  };

  const indexOfLastNotification = currentPage * NOTIFICATIONS_PER_PAGE;
  const indexOfFirstNotification =
    indexOfLastNotification - NOTIFICATIONS_PER_PAGE;
  const currentNotifications = notifications.slice(
    indexOfFirstNotification,
    indexOfLastNotification,
  );
  const totalPages = Math.ceil(notifications.length / NOTIFICATIONS_PER_PAGE);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className={styles.mawaqeetPushPageContainer}>
      <Card className={styles.notificationCard}>
        <Card.Body>
          <h2 className="text-center">Send Mawaqeet Push-melding</h2>

          <Formik
            initialValues={{
              title: "",
              imgurl: "",
              body: "",
              uid: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty, values, setFieldValue }) => (
              <FormikForm>
                <Form.Group controlId="formBody" className={styles.formGroup}>
                  <Form.Label>Meldinger beskrivelse</Form.Label>
                  <Field
                    name="body"
                    as="textarea"
                    className="form-control"
                    rows={3}
                    placeholder="Skriv melding..."
                    maxLength={MAX_CHAR_LIMIT}
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      const value = e.target.value.slice(0, MAX_CHAR_LIMIT);
                      setFieldValue("body", value);
                      setCharCount(value.length);
                    }}
                  />
                  <div className="text-muted small">
                    {charCount}/{MAX_CHAR_LIMIT} tegn
                  </div>
                  <ErrorMessage
                    name="body"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                {isLoading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    className="d-block mx-auto my-3"
                  >
                    <span className="visually-hidden">Laster...</span>
                  </Spinner>
                ) : (
                  <Button
                    variant="dark"
                    type="submit"
                    className={styles.largeButton}
                    disabled={!(isValid && dirty)}
                  >
                    Publiser melding
                  </Button>
                )}

                {error && (
                  <Alert variant="danger" className="mt-3">
                    Feilmelding: {error.text}
                  </Alert>
                )}
                {data && (
                  <Alert variant="success" className="mt-3">
                    Push-melding sendt!
                  </Alert>
                )}
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <h3 style={{ paddingTop: "40px" }}>Alle Push Meldinger</h3>
      <div>
        {isNotificationsLoading ? (
          <Spinner animation="border" />
        ) : error ? (
          <Alert variant="danger">Feil under lasting av meldinger</Alert>
        ) : notifications.length > 0 ? (
          <>
            <NotificationList notifications={currentNotifications} />
            <div className="d-flex justify-content-center my-3">
              <Button
                variant="secondary"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Forrige
              </Button>
              <span className="px-3 align-self-center">
                {currentPage} / {totalPages}
              </span>
              <Button
                variant="secondary"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Neste
              </Button>
            </div>
          </>
        ) : (
          <NoContentPlaceholder message="Ingen meldinger funnet" />
        )}
      </div>
    </div>
  );
};

export default MawaqeetPushPage;
