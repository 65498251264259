import { format } from "date-fns";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";

interface AddSpecialTimeModalProps {
  show: boolean;
  onClose: () => void;
  onSave: (
    time: string,
    info: string,
    startDate: string,
    endDate: string,
  ) => void;
  activeTab: string;
}

const MAX_INFO_LENGTH = 255;

const AddSpecialTimeModal: React.FC<AddSpecialTimeModalProps> = ({
  show,
  onClose,
  onSave,
  activeTab,
}) => {
  const [time, setTime] = useState<string>("");
  const [info, setInfo] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [charCount, setCharCount] = useState(0);

  // Handle time input to allow partial inputs and validation on blur, including blank inputs
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9]/g, "");

    if (value.length > 4) {
      value = value.slice(0, 4);
    }

    if (value.length > 2) {
      value = value.slice(0, 2) + ":" + value.slice(2);
    }

    setTime(value);
  };

  // On blur (when the input loses focus), validate or allow blank time
  const handleTimeBlur = () => {
    if (time === "") {
      setTime("");
    } else if (!/^([01]\d|2[0-3]):([0-5]\d)$/.test(time)) {
      setTime("00:00");
    }
  };

  const handleInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, MAX_INFO_LENGTH);
    setInfo(value);
    setCharCount(value.length);
  };

  const handleSave = () => {
    const formattedStartDate = startDate ? format(startDate, "dd-MM") : "";
    const formattedEndDate = endDate ? format(endDate, "dd-MM") : "";
    onSave(time, info, formattedStartDate, formattedEndDate);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Legg til ny bønnetid</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="newPrayerTime">
            <Form.Label>Bønnetid</Form.Label>
            <Form.Control
              type="text"
              placeholder="HH:MM"
              value={time}
              onChange={handleTimeChange}
              onBlur={handleTimeBlur}
            />
          </Form.Group>

          {activeTab === "jummah" && (
            <>
              <Form.Group
                controlId="newPrayerStartDate"
                className="d-flex flex-column mt-2"
              >
                <Form.Label>Start dato</Form.Label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd-MM"
                  className="form-control"
                />
              </Form.Group>
              <Form.Group
                controlId="newPrayerEndDate"
                className="d-flex flex-column mt-2"
              >
                <Form.Label>Slutt dato</Form.Label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd-MM"
                  className="form-control"
                />
              </Form.Group>
            </>
          )}

          <Form.Group controlId="newPrayerInfo" className="mt-3">
            <Form.Label>Tilleggsinformasjon</Form.Label>
            <Form.Control
              type="text"
              placeholder="Legg til tilleggsinformasjon"
              value={info}
              onChange={handleInfoChange}
              maxLength={MAX_INFO_LENGTH}
            />
            <div className="text-muted small">
              {charCount}/{MAX_INFO_LENGTH} tegn
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave}>
          Lagre
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Avbryt
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSpecialTimeModal;
