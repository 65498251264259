import React from "react";
import styles from "./JamatPageSkeleton.module.scss";

const JamatPageSkeleton: React.FC = () => {
  const skeletonRows = Array.from({ length: 7 }, (_, index) => (
    <div key={index} className={styles.skeletonRow}>
      <div className={styles.skeletonHeader}>
        <div className={styles.skeletonDate} />
        <div className={styles.skeletonActions}>
          <div className={styles.skeletonButton} />
          <div className={styles.skeletonButton} />
          <div className={styles.skeletonButton} />
        </div>
      </div>
      <div className={styles.skeletonPrayerTimes}>
        <div className={styles.skeletonTime} />
        <div className={styles.skeletonTime} />
        <div className={styles.skeletonTime} />
        <div className={styles.skeletonTime} />
        <div className={styles.skeletonTime} />
      </div>
    </div>
  ));

  return <div>{skeletonRows}</div>;
};

export default JamatPageSkeleton;
