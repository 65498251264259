import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { RoutePaths } from "enums/RoutePaths";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export interface ApiError {
  code: number;
  text: any;
}

export interface ApiResult<T> {
  data: T | null;
  isLoading: boolean;
  error: ApiError | null;
  execute: (
    url: string,
    method: RequestMethod,
    payload?: any,
    query?: any,
    customHeaders?: Record<string, string>,
  ) => Promise<T | null>;
}

type RequestMethod = "GET" | "POST" | "PATCH" | "DELETE" | "PUT";

const API_KEY = process.env.REACT_APP_API_KEY;

function useFetch<T>(): ApiResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const location = useLocation();
  const currentRoute = location.pathname;

  const execute = async (
    url: string,
    method: RequestMethod,
    payload?: any,
    params?: any,
    customHeaders?: Record<string, string>,
  ): Promise<T | null> => {
    setIsLoading(true);

    const token = sessionStorage.getItem("token");

    const headers: Record<string, string> = {
      accept: "*/*",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...customHeaders,
      ...(currentRoute !== RoutePaths.Login
        ? { "Api-key": API_KEY || "" }
        : {}),
    };

    const options: AxiosRequestConfig = {
      url: `https://api.xn--ibnn-hra.no${url}`,
      method,
      headers,
      data: payload,
      params: params,
    };

    try {
      const response = await axios(options);
      setData(response.data);
      setError(null);
      setIsLoading(false);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) {
        setError({
          code: error.response.status,
          text: error.response.data.errors,
        });
      } else {
        setError({ code: 520, text: "An error occurred." });
      }
      setData(null);
      setIsLoading(false);
    }

    return null;
  };

  return {
    data,
    isLoading,
    error,
    execute,
  };
}

export default useFetch;
