import React, { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { Card, Spinner, Alert, Button, Modal, Form } from "react-bootstrap";
import styles from "./NamesOfAllahPage.module.scss";

interface AllahName {
  id: number;
  arabic: string;
  translit: string;
  translation: string;
}

const NamesOfAllahPage: React.FC = () => {
  const { execute, isLoading, error } = useFetch();
  const [names, setNames] = useState<AllahName[]>([]);
  const [selectedName, setSelectedName] = useState<Omit<
    AllahName,
    "id"
  > | null>(null);
  const [selectedNameId, setSelectedNameId] = useState<number | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchNames = async () => {
      const response = await execute("/api/v1/names/allah", "GET");
      if (response) {
        setNames(response as AllahName[]);
      }
    };
    fetchNames();
  }, []);

  const handleEditClick = (name: AllahName) => {
    const { id, ...editableFields } = name;
    setSelectedName(editableFields);
    setSelectedNameId(id);
    setShowEditModal(true);
  };

  const handleSave = async () => {
    if (!selectedName || selectedNameId === null) return;

    setIsSubmitting(true);
    try {
      const payload = {
        ...selectedName,
        nameType: "ALLAH",
      };

      const response = await execute(
        `/api/v1/names/allah/${selectedNameId}`,
        "PATCH",
        payload,
      );

      if (response) {
        setNames((prevNames) =>
          prevNames.map((n) =>
            n.id === selectedNameId ? { ...n, ...selectedName } : n,
          ),
        );
        setShowEditModal(false);
      }
    } catch (err) {
      console.error("Error updating name:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.allahNamesContainer}>
      <h2 className="text-center mb-4">De 99 Vakre Navnene til Allah</h2>
      {isLoading ? (
        <Spinner
          animation="border"
          role="status"
          className="d-block mx-auto my-3"
        >
          <span className="visually-hidden">Laster...</span>
        </Spinner>
      ) : error ? (
        <Alert variant="danger">
          Feil ved innhenting av navn: {error.text}
        </Alert>
      ) : (
        <div className={styles.namesGrid}>
          {names.map((name) => (
            <Card key={name.id} className={styles.nameCard}>
              <Card.Body>
                <Card.Text className="text-muted">
                  <strong>ID:</strong> {name.id}
                </Card.Text>
                <Card.Title className={styles.arabicName}>
                  {name.arabic}
                </Card.Title>
                <Card.Subtitle className={`text-muted ${styles.translitName}`}>
                  {name.translit}
                </Card.Subtitle>
                <Card.Text className={styles.translationName}>
                  {name.translation}
                </Card.Text>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => handleEditClick(name)}
                >
                  Rediger
                </Button>
              </Card.Body>
            </Card>
          ))}
        </div>
      )}

      {/* Edit Modal */}
      {selectedName && (
        <Modal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Rediger Navn</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Arabisk</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedName.arabic}
                  onChange={(e) =>
                    setSelectedName({ ...selectedName, arabic: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Translitterasjon</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedName.translit}
                  onChange={(e) =>
                    setSelectedName({
                      ...selectedName,
                      translit: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Oversettelse</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedName.translation}
                  onChange={(e) =>
                    setSelectedName({
                      ...selectedName,
                      translation: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowEditModal(false)}
              disabled={isSubmitting}
            >
              Avbryt
            </Button>
            <Button
              variant="primary"
              onClick={handleSave}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Lagrer..." : "Lagre"}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default NamesOfAllahPage;
