import React, { useEffect, useState } from "react";
import { Form, Card, Pagination, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import useFetch from "hooks/useFetch";

interface Location {
  id: number;
  name: string;
  lat: number;
  lon: number;
  county: string;
  municipality: string;
  prayerTimesTables: {
    id: number;
    name: string;
  }[];
}

interface PrayerTimeTable {
  id: number;
  name: string;
}

const LocationsPage: React.FC = () => {
  const { execute } = useFetch();
  const [locations, setLocations] = useState<Location[]>([]);
  const [filteredLocations, setFilteredLocations] = useState<Location[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [showModal, setShowModal] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    null,
  );
  const [prayerTimeTables, setPrayerTimeTables] = useState<PrayerTimeTable[]>(
    [],
  );
  const [selectedPrayerTable, setSelectedPrayerTable] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [filteredPrayerTimeOptions, setFilteredPrayerTimeOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedLocationDeleteId, setSelectedLocationDeleteId] = useState<
    number | null
  >(null);
  const [selectedDeletePrayerTableName, setSelectedDeletePrayerTableName] =
    useState<string | null>(null);
  const [showCreateLocationModal, setShowCreateLocationModal] = useState(false);
  const [newLocation, setNewLocation] = useState({
    name: "",
    lat: 0,
    lon: 0,
    districtCode: "",
    county: "",
    municipality: "",
    information: "",
  });
  const [showCreatePrayerTableModal, setShowCreatePrayerTableModal] =
    useState(false);
  const [newPrayerTableName, setNewPrayerTableName] = useState("");

  const fetchLocations = async () => {
    try {
      const response = (await execute(
        "/api/v1/locations",
        "GET",
      )) as Location[];
      if (response) setLocations(response);
    } catch (error) {
      console.error("Feil ved henting av lokasjoner:", error);
    }
  };

  const fetchPrayerTimeTables = async (locationId: number) => {
    try {
      const response = (await execute(
        "/api/v1/prayer-times-tables",
        "GET",
      )) as PrayerTimeTable[];
      if (response) {
        const assignedTableIds = new Set(
          locations
            .find((loc) => loc.id === locationId)
            ?.prayerTimesTables.map((table) => table.id) || [],
        );
        setPrayerTimeTables(response);

        const options = response
          .filter((table) => !assignedTableIds.has(table.id))
          .map((table) => ({
            value: table.id,
            label: table.name,
          }));

        setFilteredPrayerTimeOptions(options);
      }
    } catch (error) {
      console.error("Feil ved henting av bønnetidstabeller:", error);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const filtered = locations.filter((location) =>
        location.name.toLowerCase().includes(lowerSearchTerm),
      );
      setFilteredLocations(filtered);
    } else {
      setFilteredLocations([]);
    }
    setCurrentPage(1);
  }, [searchTerm, locations]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLocations.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleAddPrayerTimeTable = (locationId: number) => {
    setSelectedLocationId(locationId);
    fetchPrayerTimeTables(locationId);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedLocationId(null);
    setSelectedPrayerTable(null);
  };

  const handleModalSubmit = async () => {
    if (selectedLocationId === null || selectedPrayerTable === null) {
      alert("Vennligst velg en bønnetidstabell.");
      return;
    }

    const payload = {
      locationId: selectedLocationId,
      prayerTimeTableId: selectedPrayerTable.value,
    };

    try {
      await execute(
        "/api/v1/prayer-times-tables-with-location",
        "POST",
        payload,
      );
      alert("Bønnetidstabell lagt til lokasjon!");
      fetchLocations();
      handleModalClose();
    } catch (error) {
      console.error("Feil ved oppretting av bønnetidstabell:", error);
      alert("Kunne ikke koble bønnetidstabell til lokasjon.");
    }
  };

  const handleShowDeleteModal = (
    locationId: number,
    prayerTableId: number,
    prayerTableName: string,
  ) => {
    setSelectedLocationDeleteId(locationId);
    setSelectedDeletePrayerTableName(prayerTableName);
    setSelectedPrayerTable({ value: prayerTableId, label: prayerTableName });
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedLocationDeleteId === null || !selectedPrayerTable) return;

    try {
      await execute(
        `/api/v1/prayer-times-tables-with-location/${selectedLocationDeleteId}/${selectedPrayerTable.value}`,
        "DELETE",
      );
      alert("Bønnetidstabell slettet!");
      fetchLocations();
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Feil ved sletting av bønnetidstabell:", error);
      alert("Kunne ikke slette bønnetidstabellen.");
    }
  };

  const handleCreateLocationModalClose = () => {
    setShowCreateLocationModal(false);
    setNewLocation({
      name: "",
      lat: 0,
      lon: 0,
      districtCode: "",
      county: "",
      municipality: "",
      information: "",
    });
  };

  const handleCreateLocation = async () => {
    try {
      await execute("/api/v1/locations", "POST", newLocation);
      alert("Ny lokasjon opprettet!");
      fetchLocations();
      handleCreateLocationModalClose();
    } catch (error) {
      console.error("Feil ved oppretting av ny lokasjon:", error);
      alert("Kunne ikke opprette lokasjonen.");
    }
  };

  const handleCreatePrayerTimeTable = async () => {
    if (!newPrayerTableName.trim()) {
      alert("Navn på bønnetidstabell kan ikke være tomt.");
      return;
    }

    const payload = { name: newPrayerTableName };

    try {
      await execute("/api/v1/prayer-times-tables", "POST", payload);
      alert("Ny bønnetidstabell opprettet!");
      setShowCreatePrayerTableModal(false);
      setNewPrayerTableName("");
      fetchPrayerTimeTables(selectedLocationId || 0);
    } catch (error) {
      console.error("Feil ved oppretting av bønnetidstabell:", error);
      alert("Kunne ikke opprette bønnetidstabellen.");
    }
  };

  return (
    <div className="container mt-4">
      <h1>Lokasjoner</h1>
      <div className="d-flex gap-3 align-items-center">
        <Button
          variant="primary"
          onClick={() => setShowCreateLocationModal(true)}
        >
          Opprett Ny Lokasjon
        </Button>
        <Button
          variant="success"
          onClick={() => setShowCreatePrayerTableModal(true)}
        >
          Opprett Ny Bønnetidstabell
        </Button>
      </div>

      <br />

      <div className="search-field mb-4">
        <Form.Control
          type="text"
          placeholder="Søk etter lokasjoner..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {currentItems.length > 0 ? (
        <div className="locations-list">
          {currentItems.map((location) => (
            <Card key={location.id} className="mb-3">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <strong>{location.name}</strong>
              </Card.Header>
              <Card.Body>
                <p>
                  <strong>Fylke:</strong> {location.county}
                </p>
                <p>
                  <strong>Kommune:</strong> {location.municipality}
                </p>
                <p>
                  <strong>Koordinater:</strong> {location.lat}, {location.lon}
                </p>
                <hr />
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <strong>Bønnetidstabeller:</strong>
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => handleAddPrayerTimeTable(location.id)}
                    >
                      Legg til Bønnetidstabell
                    </Button>
                  </div>
                  <div className="prayer-times-tables mt-2">
                    {location.prayerTimesTables.length > 0 ? (
                      location.prayerTimesTables.map((table) => (
                        <div
                          key={table.id}
                          className="prayer-table-item d-flex align-items-center justify-content-between p-2 mb-2 rounded border"
                          style={{ backgroundColor: "#f8f9fa" }}
                        >
                          <span>{table.name}</span>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() =>
                              handleShowDeleteModal(
                                location.id,
                                table.id,
                                table.name,
                              )
                            }
                          >
                            Slett
                          </Button>
                        </div>
                      ))
                    ) : (
                      <p className="text-muted">Ingen tabeller</p>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          ))}
        </div>
      ) : (
        searchTerm && <p>Ingen lokasjoner funnet.</p>
      )}

      {filteredLocations.length > itemsPerPage && (
        <Pagination>
          {Array.from(
            { length: Math.ceil(filteredLocations.length / itemsPerPage) },
            (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ),
          )}
        </Pagination>
      )}

      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Legg til Bønnetidstabell</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="prayerTimeTableSelect">
            <Form.Label>Velg Bønnetidstabell</Form.Label>
            <Select
              options={filteredPrayerTimeOptions}
              onChange={(option) => setSelectedPrayerTable(option)}
              placeholder="Velg en bønnetidstabell"
              isClearable
              noOptionsMessage={() => "Ingen bønnetidstabeller tilgjengelige"}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Avbryt
          </Button>
          <Button
            variant="primary"
            onClick={handleModalSubmit}
            disabled={!selectedPrayerTable}
          >
            Legg til
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Bekreft sletting av <strong>{selectedDeletePrayerTableName}</strong>
            -tabellen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Er du sikker på at du vil slette denne bønnetidstabellen?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Avbryt
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Slett
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCreateLocationModal}
        onHide={handleCreateLocationModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Opprett Ny Lokasjon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="locationName">
              <Form.Label>Navn</Form.Label>
              <Form.Control
                type="text"
                value={newLocation.name}
                onChange={(e) =>
                  setNewLocation({ ...newLocation, name: e.target.value })
                }
                placeholder="Skriv inn navn"
              />
            </Form.Group>
            <Form.Group controlId="locationLat">
              <Form.Label>Breddegrad</Form.Label>
              <Form.Control
                type="number"
                value={newLocation.lat}
                onChange={(e) =>
                  setNewLocation({
                    ...newLocation,
                    lat: Number(e.target.value),
                  })
                }
                placeholder="Skriv inn breddegrad"
              />
            </Form.Group>
            <Form.Group controlId="locationLon">
              <Form.Label>Lengdegrad</Form.Label>
              <Form.Control
                type="number"
                value={newLocation.lon}
                onChange={(e) =>
                  setNewLocation({
                    ...newLocation,
                    lon: Number(e.target.value),
                  })
                }
                placeholder="Skriv inn lengdegrad"
              />
            </Form.Group>
            <Form.Group controlId="locationCounty">
              <Form.Label>Fylke</Form.Label>
              <Form.Control
                type="text"
                value={newLocation.county}
                onChange={(e) =>
                  setNewLocation({ ...newLocation, county: e.target.value })
                }
                placeholder="Skriv inn fylke"
              />
            </Form.Group>
            <Form.Group controlId="locationMunicipality">
              <Form.Label>Kommune</Form.Label>
              <Form.Control
                type="text"
                value={newLocation.municipality}
                onChange={(e) =>
                  setNewLocation({
                    ...newLocation,
                    municipality: e.target.value,
                  })
                }
                placeholder="Skriv inn kommune"
              />
            </Form.Group>
            <Form.Group controlId="locationInfo">
              <Form.Label>Informasjon</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={newLocation.information}
                onChange={(e) =>
                  setNewLocation({
                    ...newLocation,
                    information: e.target.value,
                  })
                }
                placeholder="Skriv inn informasjon"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCreateLocationModalClose}>
            Avbryt
          </Button>
          <Button variant="primary" onClick={handleCreateLocation}>
            Opprett
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCreatePrayerTableModal}
        onHide={() => setShowCreatePrayerTableModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Opprett Ny Bønnetidstabell</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="prayerTableName">
            <Form.Label>Navn på Bønnetidstabell</Form.Label>
            <Form.Control
              type="text"
              value={newPrayerTableName}
              onChange={(e) => setNewPrayerTableName(e.target.value)}
              placeholder="Skriv inn navnet på bønnetidstabellen"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowCreatePrayerTableModal(false)}
          >
            Avbryt
          </Button>
          <Button variant="primary" onClick={handleCreatePrayerTimeTable}>
            Opprett
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LocationsPage;
