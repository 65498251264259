import React from "react";
import Button from "stories/Button/Button";
import styles from "./menuItem.module.scss";

interface MenuItemProps {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ label, icon, onClick }) => {
  return (
    <div className={styles.menuItem}>
      <Button
        label={label}
        icon={icon}
        onClick={onClick}
        className={styles.fullWidthButton}
        variant="fullWidthTransparentPrimary"
        size="large"
      />
    </div>
  );
};

export default MenuItem;
