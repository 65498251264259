import {
  faEdit,
  faEye,
  faEyeSlash,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "AuthProvider";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import styles from "./InformationPage.module.scss";
import NoContentPlaceholder from "stories/NoContentPlaceholder/NoContentPlaceholder";

interface InformationForm {
  title: string;
  description: string;
  selectedColor: string;
  activated: boolean;
}

interface Information {
  id: number;
  tittel: string;
  tekst: string;
  color: string;
  date: string;
  showInApp: boolean;
  activated: boolean;
  image?: string;
  mosqueOrgNr: string;
}

const MAX_DESCRIPTION_LENGTH = 255;

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Overskrift er påkrevd"),
  description: Yup.string()
    .required("Beskrivelse er påkrevd")
    .max(MAX_DESCRIPTION_LENGTH, `Maks ${MAX_DESCRIPTION_LENGTH} tegn tillatt`),
  selectedColor: Yup.string(),
});

const colorOptions = [
  { name: "green", hex: "#E1F5B7" },
  { name: "blue", hex: "#BDD7F8" },
  { name: "orange", hex: "#FFD4BC" },
  { name: "purple", hex: "#BEBEE9" },
  { name: "white", hex: "#FFFFFF" },
];

const InformationPage: React.FC = () => {
  const { orgNumber, selectedOrgNumber } = useAuth();
  const { data, isLoading, error, execute } = useFetch<any>();
  const {
    data: infoData,
    isLoading: isInfoLoading,
    execute: fetchInformation,
  } = useFetch<Information[]>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);
  const [information, setInformation] = useState<Information[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editingInformation, setEditingInformation] =
    useState<Information | null>(null);
  const [charCount, setCharCount] = useState(0);

  const fetchData = async () => {
    const response = await fetchInformation(
      `/api/v1/information/org/${selectedOrgNumber}`,
      "GET",
    );
    if (response) {
      setInformation(response);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedOrgNumber]);

  const handleSubmit = async (values: InformationForm, { resetForm }: any) => {
    if (!selectedDate) {
      setDateError("Date is required");
      return;
    } else {
      setDateError(null);
    }

    const formattedDate = `${selectedDate.getFullYear()}-${String(
      selectedDate.getMonth() + 1,
    ).padStart(2, "0")}-${String(selectedDate.getDate()).padStart(2, "0")}`;

    const informationRequestDto = {
      date: formattedDate,
      tittel: values.title,
      tekst: values.description,
      mosqueOrgNr: selectedOrgNumber,
      activated: values.activated,
      color: values.selectedColor,
      image: "",
    };

    try {
      const response = await execute(
        "/api/v1/information",
        "POST",
        informationRequestDto,
      );

      resetForm();
      setSelectedDate(null);
      setCharCount(0);
      fetchData();
    } catch (error: any) {
      console.error("Error:", error.response || error.message || error);
    }
  };

  const handleSaveEdit = (values: InformationForm) => {
    if (!editingInformation) return;

    const currentTime = new Date();

    const formattedDate = `${currentTime.getFullYear()}-${String(
      currentTime.getMonth() + 1,
    ).padStart(2, "0")}-${String(currentTime.getDate()).padStart(2, "0")}`;

    const payload = {
      tittel: values.title,
      tekst: values.description,
      activated: values.activated,
      mosqueOrgNr: selectedOrgNumber,
      date: formattedDate,
      color: values.selectedColor,
    };

    try {
      execute(
        `/api/v1/information/${selectedOrgNumber}/${editingInformation.id}`,
        "PATCH",
        payload,
      );
      setInformation((prevInformation) =>
        prevInformation.map((info) =>
          info.id === editingInformation.id
            ? {
                ...info,
                tittel: values.title,
                tekst: values.description,
                activated: values.activated,
                color: values.selectedColor,
              }
            : info,
        ),
      );
      setShowModal(false);
      setEditingInformation(null);
    } catch (error: any) {
      console.error("Failed to update information", error);
    }
  };

  const deleteInformation = async (id: number) => {
    try {
      await execute(`/api/v1/information/${id}`, "DELETE");
      setInformation((prev) => prev.filter((info) => info.id !== id));
    } catch (error: any) {
      console.error("Failed to delete information", error);
    }
  };

  const openEditModal = (info: Information) => {
    setEditingInformation(info);
    setShowModal(true);
  };

  return (
    <div className={styles.informationPageContainer}>
      <Card className={styles.informationCard}>
        <Card.Body>
          <h2 className="text-center">Opprett Informasjon</h2>

          <Formik
            initialValues={{
              title: "",
              description: "",
              selectedColor: "",
              activated: true,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty, setFieldValue, values }) => (
              <FormikForm>
                <Form.Group controlId="formTitle" className={styles.formGroup}>
                  <Form.Label>Overskrift*</Form.Label>
                  <Field
                    name="title"
                    className="form-control"
                    placeholder="Skriv overskrift"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group
                  controlId="formDescription"
                  className={styles.formGroup}
                >
                  <Form.Label>Beskrivelse*</Form.Label>
                  <Field
                    name="description"
                    as="textarea"
                    className="form-control"
                    rows={5}
                    placeholder="Skriv beskrivelse"
                    maxLength={MAX_DESCRIPTION_LENGTH}
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      const value = e.target.value.slice(
                        0,
                        MAX_DESCRIPTION_LENGTH,
                      );
                      setFieldValue("description", value);
                      setCharCount(value.length);
                    }}
                  />
                  <div className="text-muted small">
                    {charCount}/{MAX_DESCRIPTION_LENGTH} tegn
                  </div>
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group
                  controlId="formDate"
                  className={styles.datePickerGroup}
                >
                  <Form.Label>Dato*</Form.Label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                  {dateError && <div className="text-danger">{dateError}</div>}
                </Form.Group>

                <Form.Group controlId="formColor" className={styles.formGroup}>
                  <Form.Label>Velg farge*</Form.Label>
                  <div className={styles.colorSelector}>
                    {colorOptions.map((colorOption) => (
                      <div
                        key={colorOption.hex}
                        className={styles.colorOption}
                        style={{
                          backgroundColor: colorOption.hex,
                          border:
                            values.selectedColor === colorOption.hex
                              ? "2px solid black"
                              : "1px solid #ccc",
                        }}
                        onClick={() =>
                          setFieldValue("selectedColor", colorOption.hex)
                        }
                      />
                    ))}
                  </div>
                  <ErrorMessage
                    name="selectedColor"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group controlId="formShowInApp">
                  <Form.Check
                    type="switch"
                    label="Vis dette i appen"
                    name="showInApp"
                    checked={values.activated}
                    onChange={(e) =>
                      setFieldValue("showInApp", e.target.checked)
                    }
                  />
                </Form.Group>

                {isLoading ? (
                  <Button variant="primary" disabled>
                    Lagrer...
                  </Button>
                ) : (
                  <Button
                    variant="dark"
                    type="submit"
                    size="lg"
                    className={styles.largeButton}
                    disabled={!(isValid && dirty && selectedDate)}
                  >
                    Lagre informasjon
                  </Button>
                )}

                {error && (
                  <Alert variant="danger" className="mt-3">
                    Error: {error.text}
                  </Alert>
                )}
                {data && (
                  <Alert variant="success" className="mt-3">
                    Informasjon opprettet!
                  </Alert>
                )}
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <h3>Informasjonsinnlegg</h3>
      {isInfoLoading ? (
        <Spinner animation="border" />
      ) : error ? (
        <Alert variant="danger">Error loading information</Alert>
      ) : (
        <div className={styles.informationList}>
          {information.length > 0 ? (
            information.map((info) => (
              <Card
                key={info.id}
                className={styles.informationItem}
                style={{ borderColor: info.color }}
              >
                <div
                  className={styles.colorStrip}
                  style={{ backgroundColor: info.color }}
                />
                <Card.Body>
                  <div className={styles.informationContent}>
                    <div>
                      <h4 className={styles.title}>{info.tittel}</h4>
                      <p className={styles.description}>{info.tekst}</p>
                    </div>
                  </div>
                  <div className={styles.informationFooter}>
                    <span className={styles.date}>
                      <span className={styles.visibility}>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              {info.activated
                                ? "Vises i appen"
                                : "Skjult i appen"}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon={info.activated ? faEye : faEyeSlash}
                          />
                        </OverlayTrigger>
                      </span>
                      <FontAwesomeIcon icon="calendar" />{" "}
                      {new Date(info.date).toLocaleDateString()}
                    </span>
                    <div className={styles.informationActions}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => openEditModal(info)}
                        className={styles.editIcon}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => deleteInformation(info.id)}
                        className={styles.deleteIcon}
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ))
          ) : (
            <NoContentPlaceholder message="Ingen innlegg å vise" />
          )}

          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Edit Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {editingInformation && (
                <Formik
                  initialValues={{
                    title: editingInformation.tittel,
                    description: editingInformation.tekst,
                    activated: editingInformation.activated,
                    selectedColor: editingInformation.color,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSaveEdit}
                >
                  {({ isValid, dirty, values, setFieldValue }) => (
                    <FormikForm>
                      <Form.Group
                        controlId="formTittel"
                        className={styles.formGroup}
                      >
                        <Form.Label>Tittel*</Form.Label>
                        <Field
                          name="title"
                          className="form-control"
                          placeholder="Rediger blogg overskrift"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="formTekst"
                        className={styles.formGroup}
                      >
                        <Form.Label>Tekst*</Form.Label>
                        <Field
                          name="description"
                          as="textarea"
                          className="form-control"
                          rows={5}
                          placeholder="Rediger blogg innhold"
                          maxLength={MAX_DESCRIPTION_LENGTH}
                          onInput={(
                            e: React.ChangeEvent<HTMLTextAreaElement>,
                          ) => {
                            const value = e.target.value.slice(
                              0,
                              MAX_DESCRIPTION_LENGTH,
                            );
                            setFieldValue("description", value);
                            setCharCount(value.length);
                          }}
                        />
                        <div className="text-muted small">
                          {charCount}/{MAX_DESCRIPTION_LENGTH} tegn
                        </div>
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="formEditColor"
                        className={styles.formGroup}
                      >
                        <Form.Label>Velg ny farge*</Form.Label>
                        <div className={styles.colorSelector}>
                          {colorOptions.map((colorOption) => (
                            <div
                              key={colorOption.hex}
                              className={styles.colorOption}
                              style={{
                                backgroundColor: colorOption.hex,
                                border:
                                  values.selectedColor === colorOption.hex
                                    ? "2px solid black"
                                    : "1px solid #ccc",
                              }}
                              onClick={() =>
                                setFieldValue("selectedColor", colorOption.hex)
                              }
                            />
                          ))}
                        </div>
                        <ErrorMessage
                          name="selectedColor"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group controlId="formActivated">
                        <Form.Check
                          type="switch"
                          label="Vis dette i appen"
                          name="activated"
                          checked={values.activated}
                          onChange={(e) =>
                            setFieldValue("activated", e.target.checked)
                          }
                        />
                      </Form.Group>

                      <Button
                        variant="dark"
                        type="submit"
                        className={styles.largeButton}
                        disabled={!isValid}
                      >
                        Lagre Endringer
                      </Button>
                    </FormikForm>
                  )}
                </Formik>
              )}
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default InformationPage;
