import {
  faBell,
  faMapLocation,
  faPray,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "AuthProvider";
import React from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { MenuItem, SideMenu } from "stories";
import { ReactComponent as DefaultLogo } from "../../assets/default-logo.svg";
import styles from "./MawaqeetAdminSideMenu.module.scss";
import { getAuth, signOut } from "firebase/auth";

const mawaqeetAdminPanels = [
  {
    id: "mawaqeet-bonnetider",
    label: "Bønnetider",
    icon: <FontAwesomeIcon icon={faPray} />,
  },
  {
    id: "mawaqeet-push-meldinger",
    label: "Push meldinger",
    icon: <FontAwesomeIcon icon={faBell} />,
  },
  {
    id: "mawaqeet-lokasjoner",
    label: "Lokasjoner",
    icon: <FontAwesomeIcon icon={faMapLocation} />,
  },
];

const MawaqeetAdminSideMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setOrgNumber, setUid, setMawaqeetAdminMode } = useAuth();

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);

      sessionStorage.clear();

      setOrgNumber("");
      setUid("");

      navigate("/innlogging");
    } catch (error) {
      console.error("Failed to log out:", error);
      alert("Noe gikk galt under utloggingen. Prøv igjen senere.");
    }
  };

  return (
    <div className="App">
      <div className="sideMenu">
        <SideMenu>
          <div style={{ textAlign: "center" }}>
            <DefaultLogo
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            />

            <h4
              style={{
                color: "rgba(230, 233, 230, 0.444)",
                fontWeight: "bold",
              }}
            >
              Mawaqeet Admin
            </h4>
          </div>

          {mawaqeetAdminPanels.map((panel) => {
            const isActive = location.pathname === `/${panel.id}`;
            return (
              <Link
                to={`/${panel.id}`}
                key={panel.id}
                className={isActive ? styles.activeLink : ""}
              >
                <MenuItem label={panel.label} icon={panel.icon} />
              </Link>
            );
          })}

          <div style={{ marginTop: "auto", padding: "10px" }}>
            <button
              onClick={handleLogout}
              className="btn btn-primary"
              style={{ width: "100%" }}
            >
              Logg ut
            </button>
          </div>
        </SideMenu>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default MawaqeetAdminSideMenu;
