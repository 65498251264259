import React, { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { Card, Spinner, Alert, Button, Modal, Form } from "react-bootstrap";
import styles from "./ExpressionsPage.module.scss";

interface Expression {
  id: number;
  arabic: string;
  translit: string;
  translation: string;
  situation: string;
}

const ExpressionsList: React.FC = () => {
  const { execute, isLoading, error } = useFetch();
  const [expressions, setExpressions] = useState<Expression[]>([]);
  const [selectedExpression, setSelectedExpression] = useState<Omit<
    Expression,
    "id"
  > | null>(null);

  const [newExpression, setNewExpression] = useState<Omit<Expression, "id">>({
    arabic: "",
    translit: "",
    translation: "",
    situation: "",
  });
  const [selectedExpressionId, setSelectedExpressionId] = useState<
    number | null
  >(null);
  const [selectedDeleteExpressionId, setSelectedDeleteExpressionId] = useState<
    number | null
  >(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchExpressions = async () => {
      const response = await execute("/api/v1/expressions", "GET");
      if (response) {
        setExpressions(response as Expression[]);
      }
    };
    fetchExpressions();
  }, []);

  const handleEditClick = (expression: Expression) => {
    const { id, ...editableFields } = expression;
    setSelectedExpression(editableFields);
    setSelectedExpressionId(id);
    setShowEditModal(true);
  };

  const handleSave = async () => {
    if (!selectedExpression || selectedExpressionId === null) return;

    setIsSubmitting(true);
    try {
      const payload = { ...selectedExpression };

      const response = await execute(
        `/api/v1/expressions/${selectedExpressionId}`,
        "PATCH",
        payload,
      );

      if (response) {
        setExpressions((prevExpressions) =>
          prevExpressions.map((exp) =>
            exp.id === selectedExpressionId
              ? { ...exp, ...selectedExpression }
              : exp,
          ),
        );
        setShowEditModal(false);
      }
    } catch (err) {
      console.error("Error updating expression:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddNew = async () => {
    setIsSubmitting(true);
    try {
      const response = await execute(
        "/api/v1/expressions",
        "POST",
        newExpression,
      );

      if (response) {
        setExpressions((prevExpressions) => [
          ...prevExpressions,
          response as Expression,
        ]);
        setShowAddModal(false);
        setNewExpression({
          arabic: "",
          translit: "",
          translation: "",
          situation: "",
        });
      }
    } catch (err) {
      console.error("Feil ved oppretting av nytt uttrykk:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteClick = (expressionId: number) => {
    setSelectedDeleteExpressionId(expressionId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedDeleteExpressionId === null) return;

    setIsSubmitting(true);
    try {
      await execute(
        `/api/v1/expressions/${selectedDeleteExpressionId}`,
        "DELETE",
      );

      setExpressions((prevExpressions) =>
        prevExpressions.filter((exp) => exp.id !== selectedDeleteExpressionId),
      );
      setShowDeleteModal(false);
      setSelectedDeleteExpressionId(null);
    } catch (err) {
      console.error("Feil ved sletting av uttrykk:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.expressionsContainer}>
      <h2 className="text-center mb-4">Vanlige Arabiske Uttrykk</h2>

      <Button
        variant="success"
        className="mb-4"
        onClick={() => setShowAddModal(true)}
      >
        Legg til Uttrykk
      </Button>

      {isLoading ? (
        <Spinner
          animation="border"
          role="status"
          className="d-block mx-auto my-3"
        >
          <span className="visually-hidden">Laster...</span>
        </Spinner>
      ) : error ? (
        <Alert variant="danger">
          Feil under henting av uttrykk: {error.text}
        </Alert>
      ) : (
        <div className={styles.expressionsGrid}>
          {expressions.map((expression) => (
            <Card key={expression.id} className={styles.expressionCard}>
              <Card.Body className={styles.cardBody}>
                <div>
                  <Card.Text className="text-muted">
                    <strong>ID:</strong> {expression.id}
                  </Card.Text>
                  <Card.Title className={styles.arabicText}>
                    {expression.arabic}
                  </Card.Title>
                  <Card.Subtitle
                    className={`text-muted ${styles.translitText}`}
                  >
                    {expression.translit}
                  </Card.Subtitle>
                  <Card.Text className={styles.translationText}>
                    {expression.translation}
                  </Card.Text>
                  <Card.Text className={`text-muted ${styles.situationText}`}>
                    {expression.situation && (
                      <>
                        <strong>Situasjon:</strong> {expression.situation}
                      </>
                    )}
                  </Card.Text>
                </div>
                <div className={styles.buttonGroup}>
                  <Button
                    variant="primary"
                    size="sm"
                    className="me-2"
                    onClick={() => handleEditClick(expression)}
                  >
                    Rediger
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDeleteClick(expression.id)}
                  >
                    Slett
                  </Button>
                </div>
              </Card.Body>
            </Card>
          ))}
        </div>
      )}

      {selectedExpression && (
        <Modal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Rediger Uttrykk</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Arabisk</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedExpression.arabic}
                  onChange={(e) =>
                    setSelectedExpression({
                      ...selectedExpression,
                      arabic: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Translitterasjon</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedExpression.translit}
                  onChange={(e) =>
                    setSelectedExpression({
                      ...selectedExpression,
                      translit: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Oversettelse</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedExpression.translation}
                  onChange={(e) =>
                    setSelectedExpression({
                      ...selectedExpression,
                      translation: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Situasjon</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedExpression.situation}
                  onChange={(e) =>
                    setSelectedExpression({
                      ...selectedExpression,
                      situation: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowEditModal(false)}
              disabled={isSubmitting}
            >
              Avbryt
            </Button>
            <Button
              variant="primary"
              onClick={handleSave}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Lagrer..." : "Lagre"}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Legg til Nytt Uttrykk</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Arabisk</Form.Label>
              <Form.Control
                type="text"
                value={newExpression.arabic}
                onChange={(e) =>
                  setNewExpression({ ...newExpression, arabic: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Translitterasjon</Form.Label>
              <Form.Control
                type="text"
                value={newExpression.translit}
                onChange={(e) =>
                  setNewExpression({
                    ...newExpression,
                    translit: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Oversettelse</Form.Label>
              <Form.Control
                type="text"
                value={newExpression.translation}
                onChange={(e) =>
                  setNewExpression({
                    ...newExpression,
                    translation: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Situasjon</Form.Label>
              <Form.Control
                type="text"
                value={newExpression.situation}
                onChange={(e) =>
                  setNewExpression({
                    ...newExpression,
                    situation: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddModal(false)}
            disabled={isSubmitting}
          >
            Avbryt
          </Button>
          <Button
            variant="success"
            onClick={handleAddNew}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Legger til..." : "Legg til"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Bekreft Sletting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Er du sikker på at du vil slette dette uttrykket?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteModal(false)}
            disabled={isSubmitting}
          >
            Avbryt
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteConfirm}
            disabled={isSubmitting}
          >
            Slett
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ExpressionsList;
