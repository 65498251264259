import { useAuth } from "AuthProvider";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import useFetch from "hooks/useFetch";
import React, { useState } from "react";
import { Alert, Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import styles from "./GlobalPushPage.module.scss";

interface PushNotificationForm {
  body: string;
}

const MAX_CHAR_LIMIT = 255;

const validationSchema = Yup.object().shape({
  body: Yup.string()
    .required("Melding er påkrevd")
    .max(MAX_CHAR_LIMIT, `Melding kan ikke overstige ${MAX_CHAR_LIMIT} tegn`),
});

const GlobalPushPage: React.FC = () => {
  const { uid, selectedOrgNumber } = useAuth();
  const { isLoading, error, execute } = useFetch<any>();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [charCount, setCharCount] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [formValues, setFormValues] = useState<PushNotificationForm | null>(
    null,
  );

  const handleSubmit = (values: PushNotificationForm) => {
    setFormValues(values);
    setShowConfirmModal(true);
  };

  const handleConfirmSubmit = async () => {
    if (!formValues) return;

    const currentDate = new Date().toISOString();

    const payload = {
      uid: uid,
      orgnr: selectedOrgNumber,
      title: "",
      imgurl: "",
      body: formValues.body,
      date: currentDate,
    };

    const response = await execute(
      "/api/v1/notification/send-notification-to-all",
      "POST",
      payload,
    );

    if (response) {
      setSuccessMessage("Push-melding sendt!");
      setFormValues(null);
      setCharCount(0);
      setShowConfirmModal(false);
    }
  };

  return (
    <div className={styles.globalPushPageContainer}>
      <Card className={styles.notificationCard}>
        <Card.Body>
          <h2 className="text-center">Send Global Push-melding</h2>

          <Formik
            initialValues={{
              body: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty, values, setFieldValue }) => (
              <FormikForm>
                <Form.Group controlId="formBody" className={styles.formGroup}>
                  <Form.Label>Melding</Form.Label>
                  <Field
                    name="body"
                    as="textarea"
                    className="form-control"
                    rows={3}
                    placeholder="Skriv melding..."
                    maxLength={MAX_CHAR_LIMIT}
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      const value = e.target.value.slice(0, MAX_CHAR_LIMIT);
                      setFieldValue("body", value);
                      setCharCount(value.length);
                    }}
                  />
                  <div className="text-muted small">
                    {charCount}/{MAX_CHAR_LIMIT} tegn
                  </div>
                  <ErrorMessage
                    name="body"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                {isLoading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    className="d-block mx-auto my-3"
                  >
                    <span className="visually-hidden">Laster...</span>
                  </Spinner>
                ) : (
                  <Button
                    variant="dark"
                    type="button"
                    onClick={() => handleSubmit(values)}
                    className={styles.largeButton}
                    disabled={!(isValid && dirty)}
                  >
                    Publiser melding
                  </Button>
                )}

                {error && (
                  <Alert variant="danger" className="mt-3">
                    Feilmelding: {error.text}
                  </Alert>
                )}
                {successMessage && (
                  <Alert variant="success" className="mt-3">
                    {successMessage}
                  </Alert>
                )}
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Global Push Melding</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Er du sikker på at du vil sende denne globale push-meldingen til alle
          brukere?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmModal(false)}
          >
            Avbryt
          </Button>
          <Button variant="primary" onClick={handleConfirmSubmit}>
            Bekreft og Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GlobalPushPage;
