import styles from "./button.module.scss";

interface ButtonProps {
  disabled?: boolean;
  variant?:
    | "primary"
    | "secondary"
    | "info"
    | "error"
    | "fullWidthTransparentPrimary";
  backgroundColor?: string;
  size?: "small" | "medium" | "large";
  className?: string;
  label?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

const Button = ({
  disabled = false,
  variant = "primary",
  size = "medium",
  backgroundColor,
  label,
  icon,
  className = "",
  ...props
}: ButtonProps) => {
  const variantClass =
    styles[
      variant === "fullWidthTransparentPrimary"
        ? "storybookButtonFullWidthTransparentPrimary"
        : `storybookButton${variant.charAt(0).toUpperCase() + variant.slice(1)}`
    ];
  const sizeClass =
    styles[`storybookButton${size.charAt(0).toUpperCase() + size.slice(1)}`];
  const disabledClass = disabled ? styles.storybookButtonDisabled : "";

  return (
    <button
      type="button"
      className={`${styles.storybookButton} ${sizeClass} ${variantClass} ${disabledClass} ${className}`}
      style={{ backgroundColor }}
      {...props}
      data-testid="button"
      disabled={disabled}
    >
      {icon && <span className={styles.iconWrapper}>{icon}</span>}
      {label && <span>{label}</span>}
    </button>
  );
};

export default Button;
