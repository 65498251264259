import { useAuth } from "AuthProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import useFetch from "hooks/useFetch";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { JamatResponse, PrayerTimes } from "types/JamatPageTypes";
import styles from "./JamatPage.module.scss";
import JamatPageSkeleton from "./JamatPageSkeleton/JamatPageSkeleton";
import PrayerTimeRow from "./PrayerTimeRow/PrayerTimeRow";

const JamatPage: React.FC = () => {
  const { orgNumber, selectedOrgNumber } = useAuth();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [editTimes, setEditTimes] = useState<Record<string, PrayerTimes>>({});
  const [csvData, setCsvData] = useState<any[]>([]);
  const { isLoading, execute } = useFetch<JamatResponse[]>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const orgNr = orgNumber || "";

  const fetchPrayerTimes = async () => {
    const month = (currentMonth.getMonth() + 1).toString().padStart(2, "0");
    const url = `/api/v1/jamat/${selectedOrgNumber}/${month}`;

    const result = await execute(url, "GET");
    if (result) {
      const newPrayerTimesData = result.reduce<Record<string, PrayerTimes>>(
        (acc, item) => {
          acc[item.startDate] = mapToPrayerTimes(item);
          return acc;
        },
        {},
      );
      setEditTimes(newPrayerTimesData);
    }
  };

  const formatPrayerTime = (time: string) =>
    time === "00:00" || !time ? "" : time;

  const mapToPrayerTimes = (item: JamatResponse): PrayerTimes => ({
    id: item.id,
    fajr: formatPrayerTime(item.fajr),
    duhr: formatPrayerTime(item.duhr),
    asr: formatPrayerTime(item.asr),
    maghrib: formatPrayerTime(item.maghrib),
    isha: formatPrayerTime(item.isha),
    fajrJamatOffset: item.fajrJamatOffset ?? -1,
    duhrJamatOffset: item.duhrJamatOffset ?? -1,
    asrJamatOffset: item.asrJamatOffset ?? -1,
    maghribJamatOffset: item.maghribJamatOffset ?? -1,
    ishaJamatOffset: item.ishaJamatOffset ?? -1,
  });

  useEffect(() => {
    fetchPrayerTimes();
  }, [currentMonth, selectedOrgNumber]);

  const fetchCsvData = async () => {
    const url = `/api/v1/jamat/${selectedOrgNumber}/download-csv`;
    try {
      const result = await execute(url, "GET");
      setCsvData(result || []);
    } catch (error) {
      console.error("Failed to fetch CSV data", error);
    }
  };

  useEffect(() => {
    fetchCsvData();
  }, []);

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newMonth = new Date(currentMonth);
    newMonth.setMonth(parseInt(event.target.value));
    setCurrentMonth(newMonth);
  };

  const handlePrayerTimeChange = (
    dateKey: string,
    prayer: "fajr" | "duhr" | "asr" | "maghrib" | "isha",
    time: string,
  ) => {
    setEditTimes((prevEditTimes) => ({
      ...prevEditTimes,
      [dateKey]: {
        ...prevEditTimes[dateKey],
        [prayer]: time,
      },
    }));
  };

  const handleOffsetChange = (
    dateKey: string,
    offset:
      | "fajrJamatOffset"
      | "duhrJamatOffset"
      | "asrJamatOffset"
      | "maghribJamatOffset"
      | "ishaJamatOffset",
    value: number,
  ) => {
    setEditTimes((prevEditTimes) => ({
      ...prevEditTimes,
      [dateKey]: {
        ...prevEditTimes[dateKey],
        [offset]: value,
      },
    }));
  };

  const savePrayerTime = async (dateKey: string) => {
    const updatedTimes = editTimes[dateKey];
    if (updatedTimes.id) {
      await updatePrayerTime(dateKey, updatedTimes);
    } else {
      await createPrayerTime(dateKey, updatedTimes);
    }
  };

  const createPrayerTime = async (dateKey: string, times: PrayerTimes) => {
    const requestBody = {
      ...times,
      startDate: dateKey,
      mosqueOrgNr: selectedOrgNumber,
      fajrJamatOffset: times.fajrJamatOffset ?? -1,
      duhrJamatOffset: times.duhrJamatOffset ?? -1,
      asrJamatOffset: times.asrJamatOffset ?? -1,
      maghribJamatOffset: times.maghribJamatOffset ?? -1,
      ishaJamatOffset: times.ishaJamatOffset ?? -1,
    };

    const url = `/api/v1/jamat`;
    try {
      await execute(url, "POST", requestBody);
      fetchPrayerTimes();
    } catch (error) {
      console.error("Failed to create prayer time", error);
    }
  };

  const updatePrayerTime = async (dateKey: string, times: PrayerTimes) => {
    const url = `/api/v1/jamat/${selectedOrgNumber}/${times.id}`;
    try {
      await execute(url, "PATCH", times);
      fetchPrayerTimes(); // Refresh times after update
    } catch (error) {
      console.error("Failed to update prayer time", error);
    }
  };

  const handleSaveAllRows = async () => {
    const payload = Object.entries(editTimes).map(([startDate, times]) => ({
      id: times.id || 0,
      startDate,
      endDate: startDate, // Assuming single day for now
      fajr: times.fajr,
      duhr: times.duhr,
      asr: times.asr,
      maghrib: times.maghrib,
      isha: times.isha,
      mosqueOrgNr: selectedOrgNumber,
      fajrJamatOffset: times.fajrJamatOffset ?? -1,
      duhrJamatOffset: times.duhrJamatOffset ?? -1,
      asrJamatOffset: times.asrJamatOffset ?? -1,
      maghribJamatOffset: times.maghribJamatOffset ?? -1,
      ishaJamatOffset: times.ishaJamatOffset ?? -1,
    }));

    try {
      const response = await execute("/api/v1/jamat/batch", "POST", payload);
      console.log("All rows saved successfully:", response);
      alert("Alle jamattider ble lagret!");
      fetchPrayerTimes(); // Refresh after save
    } catch (error) {
      console.error("Failed to save prayer times:", error);
      alert("Failed to save prayer times. Please try again.");
    }
  };

  const deletePrayerTime = async (dateKey: string) => {
    const updatedTimes = editTimes[dateKey];
    const url = `/api/v1/jamat/${updatedTimes.id}`;
    try {
      await execute(url, "DELETE");
      fetchPrayerTimes();
    } catch (error) {
      console.error("Failed to delete prayer time", error);
    }
  };

  const renderPrayerTimes = () => {
    const daysInMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0,
    ).getDate();
    const rows = [];
    let previousTimes: PrayerTimes | null = null;

    for (let day = 1; day <= daysInMonth; day++) {
      const dateKey = `${day.toString().padStart(2, "0")}-${(
        currentMonth.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}`;
      const times = editTimes[dateKey] || {
        id: 0,
        fajr: "",
        duhr: "",
        asr: "",
        maghrib: "",
        isha: "",
        fajrJamatOffset: -1,
        duhrJamatOffset: -1,
        asrJamatOffset: -1,
        maghribJamatOffset: -1,
        ishaJamatOffset: -1,
      };

      const formattedDate = `${day
        .toString()
        .padStart(2, "0")}. ${new Intl.DateTimeFormat("default", {
        month: "long",
      }).format(
        new Date(currentMonth.getFullYear(), currentMonth.getMonth()),
      )}`;

      rows.push(
        <PrayerTimeRow
          key={dateKey}
          dateKey={dateKey}
          times={times}
          formattedDate={formattedDate}
          previousTimes={previousTimes}
          onPrayerTimeChange={handlePrayerTimeChange}
          onOffsetChange={handleOffsetChange}
          onSave={savePrayerTime}
          onCreate={createPrayerTime}
          onDelete={deletePrayerTime}
          fetchLatestPrayerTimes={fetchPrayerTimes}
        />,
      );

      previousTimes = times;
    }

    return <div>{rows}</div>;
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadUrl = `/api/v1/jamat/${selectedOrgNumber}/upload-csv`;
        await execute(uploadUrl, "POST", formData);
        console.log("File uploaded successfully");
        fetchPrayerTimes();
      } catch (error) {
        console.error("File upload failed", error);
      }
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <h1>Jamat tider</h1>
        <button className="btn btn-primary" onClick={handleSaveAllRows}>
          Lagre alle
        </button>
        <div className={styles.csvButtons}>
          <CSVLink
            data={csvData}
            filename={`jamat_times_${selectedOrgNumber}.csv`}
            className="btn btn-primary ms-2"
          >
            Eksporter til CSV
          </CSVLink>
          <button
            className="btn btn-secondary ms-2"
            onClick={() => fileInputRef.current?.click()}
          >
            Importer CSV
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".csv"
            onChange={(event) => handleFileUpload(event)}
          />
        </div>
      </div>
      <hr />
      <div className={styles.monthContainer}>
        <h3>Velg måned:</h3>
        <div className={`row ${styles.monthNavigation}`}>
          <div className="col">
            <select
              className="form-select"
              value={currentMonth.getMonth()}
              onChange={(e) => handleMonthChange(e)}
            >
              {Array.from({ length: 12 }, (_, index) => (
                <option key={index} value={index}>
                  {new Date(0, index).toLocaleString("default", {
                    month: "long",
                  })}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          {isLoading ? <JamatPageSkeleton /> : renderPrayerTimes()}
        </div>
      </div>

      <div className="text-end mt-3">
        <button className="btn btn-primary" onClick={handleSaveAllRows}>
          Lagre alle
        </button>
      </div>
    </div>
  );
};

export default JamatPage;
