import React, { useEffect, useState } from "react";
import { Alert, Button, Modal, Form } from "react-bootstrap";
import { useAuth } from "AuthProvider";
import useFetch from "hooks/useFetch";
import Header from "stories/Header/Header";
import PrayerTimesSkeleton from "pages/PrayerTimes/PrayerTimesSkeleton/PrayerTimesSkeleton";
import styles from "./SuperAdminPrayerTimesPage.module.scss";
import Select, { createFilter } from "react-select";
import { CSVLink } from "react-csv";

interface Location {
  id: number;
  name: string;
  prayerTimesTables: { id: number; name: string }[];
}

interface PrayerTimes {
  date: string;
  fajr: string;
  fajr_endtime: string;
  duhr: string;
  asr: string;
  maghrib: string;
  isha: string;
  asr_1x_shadow: string;
  asr_2x_shadow: string;
}

const SuperAdminPrayerTimesPage: React.FC = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    null,
  );
  const [prayerTimesTables, setPrayerTimesTables] = useState<
    { id: number; name: string }[]
  >([]);
  const [selectedTableId, setSelectedTableId] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [prayerTimesData, setPrayerTimesData] = useState<PrayerTimes[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalLocationId, setModalLocationId] = useState<number | null>(null);
  const [modalPrayerTimesTables, setModalPrayerTimesTables] = useState<
    { id: number; name: string }[]
  >([]);
  const [modalTableId, setModalTableId] = useState<number | null>(null);
  const [modalYear, setModalYear] = useState<number | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { execute, isLoading } = useFetch();

  const customFilter = createFilter({
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const result = await execute(`/api/v1/locations`, "GET");
        if (result) {
          const sortedLocations = result.sort((a: Location, b: Location) =>
            a.name.localeCompare(b.name),
          );
          setLocations(sortedLocations);
        }
      } catch (err) {
        setError("Failed to fetch locations.");
      }
    };
    fetchLocations();
  }, []);

  const fetchPrayerTimes = async () => {
    if (!selectedLocationId || !selectedTableId || selectedYear === null)
      return;

    const endpoint = `/api/v1/prayerTimes/${selectedLocationId}/${selectedTableId}/${selectedYear}`;

    try {
      const result = await execute(endpoint, "GET");
      if (!result || result.length === 0) {
        setPrayerTimesData([]);
        setError("Ingen bønnetider funnet for det valgte året.");
      } else {
        setPrayerTimesData(result);
        setError(null);
      }
    } catch (err) {
      setPrayerTimesData([]);
      setError(
        "Kunne ikke hente bønnetider for den valgte lokasjonen og tabellen.",
      );
    }
  };

  const handleLocationChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const locationId = parseInt(event.target.value);
    const selectedLocation = locations.find((loc) => loc.id === locationId);

    setSelectedLocationId(locationId);
    setPrayerTimesTables(selectedLocation?.prayerTimesTables || []);
    setSelectedTableId(null);
    setSelectedYear(null);
    setPrayerTimesData([]);
    setError(null);
  };

  const handleTableChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTableId(parseInt(event.target.value));
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(parseInt(event.target.value));
  };

  useEffect(() => {
    if (selectedLocationId && selectedTableId && selectedYear !== null) {
      fetchPrayerTimes();
    }
  }, [selectedLocationId, selectedTableId, selectedYear]);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setCsvFile(file);
      setUploadStatus(null);
    }
  };

  const handleFileUpload = async () => {
    if (!csvFile || !modalLocationId || !modalTableId || !modalYear) {
      setUploadStatus(
        "Vennligst sørg for at alle felt er valgt og at en fil er valgt.",
      );
      return;
    }

    const formData = new FormData();
    formData.append("file", csvFile);

    try {
      const uploadUrl = `/api/v1/prayerTimes/${modalLocationId}/${modalTableId}/${modalYear}/upload-csv`;
      await execute(uploadUrl, "POST", formData);
      setUploadStatus("Filen ble lastet opp!");
      console.log("Filen ble lastet opp!");
      fetchPrayerTimes();
      setCsvFile(null);
    } catch (error) {
      setUploadStatus("Kunne ikke laste opp filen.");
      console.error("Kunne ikke laste opp filen.", error);
    }
  };

  const handleDeletePrayerTimes = async () => {
    if (!selectedLocationId || !selectedTableId) {
      alert("Lokasjon og tabell må være valgt for å slette bønnetider.");
      return;
    }

    try {
      const deleteUrl = `/api/v1/prayerTimes/${selectedLocationId}/${selectedTableId}`;
      await execute(deleteUrl, "DELETE");
      alert("Bønnetider slettet!");
      setPrayerTimesData([]);
      setShowDeleteModal(false);
      fetchPrayerTimes();
    } catch (error) {
      console.error("Feil ved sletting av bønnetider:", error);
      alert("Kunne ikke slette bønnetidene. Vennligst prøv igjen.");
    }
  };

  const resetModalStates = () => {
    setModalLocationId(null);
    setModalPrayerTimesTables([]);
    setModalTableId(null);
    setModalYear(null);
    setCsvFile(null);
    setUploadStatus(null);
  };

  const formatCsvData = (data: PrayerTimes[]) => {
    const headers = [
      { label: "Dato", key: "date" },
      { label: "Fajr", key: "fajr" },
      { label: "Sunrise", key: "fajr_endtime" },
      { label: "Duhr", key: "duhr" },
      { label: "Asr", key: "asr" },
      { label: "Asr1x", key: "asr_1x_shadow" },
      { label: "Asr2x", key: "asr_2x_shadow" },
      { label: "Maghrib", key: "maghrib" },
      { label: "Isha", key: "isha" },
    ];

    const rows = data.map((row) => ({
      date: new Intl.DateTimeFormat("default", {
        day: "numeric",
        month: "long",
        year: "numeric",
      }).format(new Date(row.date)),
      fajr: row.fajr,
      fajr_endtime: row.fajr_endtime,
      duhr: row.duhr,
      asr: row.asr,
      asr_1x_shadow: row.asr_1x_shadow,
      asr_2x_shadow: row.asr_2x_shadow,
      maghrib: row.maghrib,
      isha: row.isha,
    }));

    return [headers.map((header) => header.label), ...rows.map(Object.values)];
  };

  const renderPrayerTimes = () => (
    <div className={styles.tableContainer}>
      <table className={styles.prayerTimesTable}>
        <thead>
          <tr>
            <th>Dato</th>
            <th>Fajr</th>
            <th>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-sunrise"
                viewBox="0 0 16 16"
              >
                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l1.5 1.5a.5.5 0 0 1-.708.708L8.5 2.707V4.5a.5.5 0 0 1-1 0V2.707l-.646.647a.5.5 0 1 1-.708-.708zM2.343 4.343a.5.5 0 0 1 .707 0l1.414 1.414a.5.5 0 0 1-.707.707L2.343 5.05a.5.5 0 0 1 0-.707m11.314 0a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0M8 7a3 3 0 0 1 2.599 4.5H5.4A3 3 0 0 1 8 7m3.71 4.5a4 4 0 1 0-7.418 0H.499a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1h-3.79zM0 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 10m13 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
              </svg>
            </th>
            <th>Duhr</th>
            <th>Asr</th>
            <th>Asr1x</th>
            <th>Asr2x</th>
            <th>Maghrib</th>
            <th>Isha</th>
          </tr>
        </thead>
        <tbody>
          {prayerTimesData.map((times) => (
            <tr key={times.date}>
              <td>
                {new Intl.DateTimeFormat("default", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                }).format(new Date(times.date))}
              </td>
              <td>{times.fajr}</td>
              <td>{times.fajr_endtime}</td>
              <td>{times.duhr}</td>
              <td>{times.asr}</td>
              <td>{times.asr_1x_shadow}</td>
              <td>{times.asr_2x_shadow}</td>
              <td>{times.maghrib}</td>
              <td>{times.isha}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderError = () =>
    error && (
      <Alert variant="danger" className="mt-3">
        <h4>{error}</h4>
      </Alert>
    );

  return (
    <div className={`container ${styles.prayerTimesPage}`}>
      <Button variant="primary" onClick={() => setModalVisible(true)}>
        Ny Bønnetidstabell
      </Button>
      {selectedLocationId &&
        selectedTableId &&
        selectedYear &&
        prayerTimesData.length > 0 && (
          <>
            <CSVLink
              data={formatCsvData(prayerTimesData)}
              filename={`prayer_times_${selectedLocationId}_${selectedTableId}_${selectedYear}.csv`}
              className="btn btn-success ms-2"
            >
              Eksporter til CSV
            </CSVLink>
            <Button
              variant="danger"
              className="ms-2"
              onClick={() => setShowDeleteModal(true)}
            >
              Slett Bønnetider
            </Button>
          </>
        )}
      <Header title="Bønnetider" />

      <br />
      <div className="mb-3">
        <label htmlFor="locationSelect">Velg Lokasjon:</label>
        <Select
          id="locationSelect"
          options={locations.map((location) => ({
            value: location.id,
            label: location.name,
          }))}
          onChange={(selectedOption: { value: number } | null) => {
            const locationId = selectedOption?.value || null;
            const selectedLocation = locations.find(
              (loc) => loc.id === locationId,
            );

            setSelectedLocationId(locationId);
            setPrayerTimesTables(selectedLocation?.prayerTimesTables || []);
            setSelectedTableId(null);
            setSelectedYear(null);
            setPrayerTimesData([]);
            setError(null);
          }}
          placeholder="Søk og velg lokasjon"
          filterOption={customFilter}
          noOptionsMessage={() => "Ingen lokasjoner funnet"}
        />
      </div>

      {prayerTimesTables.length > 0 ? (
        <div className="mb-3">
          <label htmlFor="tableSelect">Velg Bønnetabell:</label>
          <select
            id="tableSelect"
            className="form-select"
            value={selectedTableId || ""}
            onChange={handleTableChange}
          >
            <option value="" disabled>
              Velg en bønnetabell
            </option>
            {prayerTimesTables.map((table) => (
              <option key={table.id} value={table.id}>
                {table.name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        selectedLocationId && (
          <Alert variant="warning">
            Ingen bønnetabeller tilgjengelig for denne lokasjonen.
          </Alert>
        )
      )}

      {selectedLocationId && selectedTableId && (
        <div className="mb-3">
          <label htmlFor="yearSelect">Velg År:</label>
          <select
            id="yearSelect"
            className="form-select"
            value={selectedYear || ""}
            onChange={handleYearChange}
          >
            <option value="" disabled>
              Velg et år
            </option>
            {Array.from({ length: 7 }, (_, index) => {
              const year = new Date().getFullYear() + index;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </select>
        </div>
      )}

      <div className="row">
        <div className="col">
          {isLoading ? (
            <PrayerTimesSkeleton />
          ) : error ? (
            renderError()
          ) : (
            selectedYear !== null && renderPrayerTimes()
          )}
        </div>
      </div>

      <Modal
        show={modalVisible}
        onHide={() => {
          setModalVisible(false);
          resetModalStates();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Last opp CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Velg Lokasjon</Form.Label>
            <Select
              options={locations
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((location) => ({
                  value: location.id,
                  label: location.name,
                }))}
              value={
                modalLocationId
                  ? {
                      value: modalLocationId,
                      label: locations.find((loc) => loc.id === modalLocationId)
                        ?.name,
                    }
                  : null
              }
              onChange={(selectedOption) => {
                const locationId = selectedOption?.value || null;
                const selectedLocation = locations.find(
                  (loc) => loc.id === locationId,
                );

                setModalLocationId(locationId);
                setModalPrayerTimesTables(
                  selectedLocation?.prayerTimesTables || [],
                );
                setModalTableId(null);
                setModalYear(null);
              }}
              placeholder="Søk og velg lokasjon"
              isClearable
              filterOption={createFilter({
                ignoreCase: true,
                ignoreAccents: true,
                matchFrom: "any",
                stringify: (option) => option.label,
              })}
              noOptionsMessage={() => "Ingen lokasjoner funnet"}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Velg Bønnetabell</Form.Label>
            <Form.Control
              as="select"
              value={modalTableId || ""}
              onChange={(event) =>
                setModalTableId(parseInt(event.target.value))
              }
              disabled={!modalPrayerTimesTables.length}
            >
              <option value="" disabled>
                Velg en bønnetabell
              </option>
              {modalPrayerTimesTables.map((table) => (
                <option key={table.id} value={table.id}>
                  {table.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Velg År</Form.Label>
            <Form.Control
              as="select"
              value={modalYear || ""}
              onChange={(event) => setModalYear(parseInt(event.target.value))}
              disabled={!modalTableId}
            >
              <option value="" disabled>
                Velg et år
              </option>
              {Array.from({ length: 7 }, (_, index) => {
                const year = new Date().getFullYear() + index;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Last opp CSV-fil</Form.Label>
            <Form.Control
              type="file"
              accept=".csv"
              onChange={(e) =>
                handleFileSelect(e as React.ChangeEvent<HTMLInputElement>)
              }
            />
          </Form.Group>

          {uploadStatus && (
            <Alert
              className="mt-3"
              variant={
                uploadStatus.includes("lastet opp") ? "success" : "danger"
              }
            >
              {uploadStatus}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setModalVisible(false);
              resetModalStates();
            }}
          >
            Lukk
          </Button>
          <Button
            variant="primary"
            onClick={handleFileUpload}
            disabled={
              !csvFile || !modalLocationId || !modalTableId || !modalYear
            }
          >
            Last opp
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Bekreft Sletting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Er du sikker på at du vil slette alle bønnetider for den valgte
          lokasjonen, tabellen og året?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Avbryt
          </Button>
          <Button variant="danger" onClick={handleDeletePrayerTimes}>
            Slett
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SuperAdminPrayerTimesPage;
