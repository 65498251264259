import {
  faBell,
  faCalendar,
  faClock,
  faDonate,
  faInfoCircle,
  faKeyboard,
  faMosque,
  faNewspaper,
  faPeopleGroup,
  faPersonPraying,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "AuthProvider";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Select, { createFilter } from "react-select";
import { MenuItem, SideMenu } from "stories";
import { ReactComponent as DefaultLogo } from "../../assets/default-logo.svg";
import styles from "./LayoutWithSideMenu.module.scss";
import { getAuth, signOut } from "firebase/auth";

const panels = [
  { id: "", label: "Moskè Info", icon: <FontAwesomeIcon icon={faMosque} /> },
  {
    id: "jamat",
    label: "Jamat",
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
  },
  {
    id: "bonnetider",
    label: "Bønnetider",
    icon: <FontAwesomeIcon icon={faPersonPraying} />,
  },
  {
    id: "spesielle-bonnetider",
    label: "Spesielle Bønnetider",
    icon: <FontAwesomeIcon icon={faClock} />,
  },
  {
    id: "push-meldinger",
    label: "Push-meldinger",
    icon: <FontAwesomeIcon icon={faBell} />,
  },
  {
    id: "tv-admin",
    label: "TV-Admin",
    icon: <FontAwesomeIcon icon={faNewspaper} />,
  },
  {
    id: "aktiviteter",
    label: "Aktiviteter",
    icon: <FontAwesomeIcon icon={faCalendar} />,
  },
  {
    id: "blogg",
    label: "Blogginnlegg",
    icon: <FontAwesomeIcon icon={faKeyboard} />,
  },
  {
    id: "informasjon",
    label: "Informasjon",
    icon: <FontAwesomeIcon icon={faInfoCircle} />,
  },
  {
    id: "kampanje",
    label: "Kampanje",
    icon: <FontAwesomeIcon icon={faDonate} />,
  },
];

const LayoutWithSideMenu: React.FC = () => {
  const navigate = useNavigate();
  const { data, isLoading, error, execute } = useFetch<[]>();
  const {
    setOrgNumber,
    setUid,
    mosqueName,
    selectedOrgName,
    mosqueLogo,
    setMosqueLogo,
    setMosqueName,
    isSuperAdmin,
    selectedOrgNumber,
    setSelectedOrgNumber,
    setSelectedOrgName,
  } = useAuth();

  interface Mosque {
    orgNr: string;
    orgName: string;
    name?: string;
    logo?: string;
  }

  const [mosqueList, setMosqueList] = useState<Mosque[]>([]);

  useEffect(() => {
    const fetchMosques = async () => {
      if (isSuperAdmin) {
        const url = `/api/v1/mosques`;

        const result = await execute(url, "GET");
        if (result) {
          const sortedMosques = result.sort((a: Mosque, b: Mosque) =>
            a.orgName.localeCompare(b.orgName),
          );
          setMosqueList(sortedMosques);
        }
      }
      return;
    };

    fetchMosques();
  }, [isSuperAdmin, selectedOrgNumber]);

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);

      sessionStorage.clear();
      setOrgNumber("");
      setUid("");
      navigate("/innlogging");
    } catch (error) {
      console.error("Failed to log out:", error);
      alert("Noe gikk galt under utloggingen. Prøv igjen senere.");
    }
  };

  const handleGoToSuperAdmin = () => {
    sessionStorage.removeItem("mosqueName");
    sessionStorage.removeItem("mosqueLogo");
    sessionStorage.removeItem("prayerTimeTableId");
    sessionStorage.removeItem("selectedOrgNumber");

    navigate("/velg-moske");
  };

  const handleMosqueSelect = (selectedOption: any) => {
    setMosqueLogo("");
    setMosqueName("");

    if (selectedOption) {
      const selectedOrgNr = selectedOption.value;

      setSelectedOrgNumber(selectedOrgNr);
      sessionStorage.setItem("selectedOrgNumber", selectedOrgNr);

      setSelectedOrgName(selectedOption.orgName);
      sessionStorage.setItem("selectedOrgName", selectedOption.orgName);

      setMosqueLogo(selectedOption.logo);
      sessionStorage.setItem("mosqueLogo", selectedOption.logo);

      navigate("/");
    }
  };

  const mosqueOptions = mosqueList.map((mosque) => ({
    value: mosque.orgNr,
    label: (mosque.name || mosque.orgName)?.toUpperCase(),
    orgName: mosque.orgName,
    name: mosque.name,
    logo: mosque.logo,
  }));

  const customFilter = createFilter({
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: "any",
    stringify: (option: any) => `${option.data.orgName} ${option.data.name}`,
  });

  return (
    <div className="App">
      <div className="sideMenu">
        <SideMenu>
          <div style={{ textAlign: "center" }}>
            {mosqueLogo ? (
              <img
                src={mosqueLogo}
                alt="Mosque Logo"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  marginTop: "20px",
                  marginBottom: "20px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <DefaultLogo
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              />
            )}
            <h4
              style={{
                color: "rgba(230, 233, 230, 0.444)",
                fontWeight: "bold",
              }}
            >
              {mosqueName || selectedOrgName}
            </h4>
          </div>

          {/* Searchable Dropdown for Mosque List */}
          {isSuperAdmin && (
            <div style={{ width: "80%", margin: "0 auto" }}>
              <h5
                style={{
                  color: "rgba(230, 233, 230, 0.444)",
                  textAlign: "center",
                }}
              >
                Velg Moskè:
              </h5>
              <Select
                options={mosqueOptions}
                onChange={handleMosqueSelect}
                placeholder="Søk og velg Moské"
                filterOption={customFilter}
                value={mosqueOptions.find(
                  (option) => option.value === selectedOrgNumber,
                )}
                noOptionsMessage={() => "Ingen moskèer funnet"}
              />
            </div>
          )}

          <hr
            style={{
              border: "1px solid rgba(230, 233, 230, 0.444)",
              margin: "0 20px",
            }}
          />

          {panels.map((panel) => {
            const isActive = location.pathname === `/${panel.id}`;
            return (
              <Link
                to={`/${panel.id}`}
                key={panel.id}
                className={isActive ? styles.activeLink : ""}
              >
                <MenuItem label={panel.label} icon={panel.icon} />
              </Link>
            );
          })}

          <div style={{ marginTop: "auto", padding: "10px" }}>
            {isSuperAdmin && (
              <button
                onClick={handleGoToSuperAdmin}
                className="btn btn-secondary"
                style={{ width: "100%", marginBottom: "15px" }}
              >
                Super Admin
              </button>
            )}
            <button
              onClick={handleLogout}
              className="btn btn-primary"
              style={{ width: "100%" }}
            >
              Logg ut
            </button>
          </div>
        </SideMenu>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutWithSideMenu;
