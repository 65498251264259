import React, { useEffect, useState } from "react";
import { Form, Card, Pagination, Button, Modal } from "react-bootstrap";
import useFetch from "hooks/useFetch";

interface Location {
  id: number;
  name: string;
  lat: number;
  lon: number;
  county: string;
  municipality: string;
  prayerTimesTables: {
    id: number;
    name: string;
  }[];
}

const MawaqeetLocationsPage: React.FC = () => {
  const { execute } = useFetch();
  const [locations, setLocations] = useState<Location[]>([]);
  const [filteredLocations, setFilteredLocations] = useState<Location[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  );
  const itemsPerPage = 10;
  const prayerTimeTableId = 2;

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = (await execute(
          "/api/v1/locations",
          "GET",
        )) as Location[];
        if (response) {
          const filtered = response.filter(
            (location) =>
              !location.prayerTimesTables.some(
                (table) => table.id === prayerTimeTableId,
              ),
          );
          setLocations(filtered);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const filtered = locations.filter((location) =>
        location.name.toLowerCase().includes(lowerSearchTerm),
      );
      setFilteredLocations(filtered);
    } else {
      setFilteredLocations(locations);
    }
    setCurrentPage(1);
  }, [searchTerm, locations]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLocations.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleAddPrayerTimeTableClick = (location: Location) => {
    setSelectedLocation(location);
    setShowConfirmationModal(true);
  };

  const handleConfirmAddPrayerTimeTable = async () => {
    if (!selectedLocation) return;

    try {
      const payload = { locationId: selectedLocation.id, prayerTimeTableId };
      await execute(
        "/api/v1/prayer-times-tables-with-location",
        "POST",
        payload,
      );
      alert("Mawaqeet prayer time table added successfully!");
      setLocations((prev) =>
        prev.filter((location) => location.id !== selectedLocation.id),
      );
      setShowConfirmationModal(false);
      setSelectedLocation(null);
    } catch (error) {
      console.error("Error adding Mawaqeet prayer time table:", error);
      alert("Failed to add Mawaqeet prayer time table.");
    }
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredLocations.length / itemsPerPage);
    const maxVisiblePages = 6;
    const pages: (number | "ellipsis")[] = [];

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 6);

      pages.push(1);

      if (startPage > 2) {
        pages.push("ellipsis");
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages - 1) {
        pages.push("ellipsis");
      }

      pages.push(totalPages);
    }

    return (
      <Pagination>
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => paginate(currentPage - 1)}
        >
          Forrige
        </Pagination.Prev>
        {pages.map((page, index) =>
          page === "ellipsis" ? (
            <Pagination.Ellipsis key={`ellipsis-${index}`} />
          ) : (
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => paginate(page)}
            >
              {page}
            </Pagination.Item>
          ),
        )}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => paginate(currentPage + 1)}
        >
          Neste
        </Pagination.Next>
      </Pagination>
    );
  };

  return (
    <div className="container mt-4">
      <h1>Lokasjoner uten Mawaqeet Bønnetidstabell</h1>
      <div className="search-field mb-4">
        <Form.Control
          type="text"
          placeholder="Søk etter lokasjoner..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {currentItems.length > 0 ? (
        <div className="locations-list">
          {currentItems.map((location) => (
            <Card key={location.id} className="mb-3">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <strong>{location.name}</strong>
              </Card.Header>
              <Card.Body>
                <p>
                  <strong>Fylke:</strong> {location.county}
                </p>
                <p>
                  <strong>Kommune:</strong> {location.municipality}
                </p>
                <p>
                  <strong>Koordinater:</strong> {location.lat}, {location.lon}
                </p>
                <Button
                  variant="success"
                  onClick={() => handleAddPrayerTimeTableClick(location)}
                >
                  Legg til Mawaqeet Bønnetidstabell
                </Button>
              </Card.Body>
            </Card>
          ))}
        </div>
      ) : (
        <p>
          {searchTerm
            ? "Ingen lokasjoner funnet."
            : "Ingen lokasjoner tilgjengelige."}
        </p>
      )}

      {filteredLocations.length > itemsPerPage && renderPagination()}

      {/* Confirmation Modal */}
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Bekreft Legg til</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedLocation ? (
            <p>
              Er du sikker på at du vil legge til Mawaqeet bønnetidstabell for{" "}
              <strong>{selectedLocation.name}</strong>?
            </p>
          ) : (
            <p>Noe gikk galt. Prøv igjen senere.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmationModal(false)}
          >
            Avbryt
          </Button>
          <Button variant="success" onClick={handleConfirmAddPrayerTimeTable}>
            Bekreft
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MawaqeetLocationsPage;
