import manAndWife from "assets/man-and-wife-praying.jpg";
import { useAuth } from "AuthProvider";
import { signInWithEmailAndPassword } from "firebase/auth";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "stories/Loading/Loading";
import * as Yup from "yup";
import { ReactComponent as Icon } from "../../assets/ibonn-icon.svg";
import { auth } from "../../firebase";
import useFetch from "../../hooks/useFetch";
import styles from "./Login.module.scss";

const validationSchema = Yup.object({
  username: Yup.string().required("Brukernavn er påkrevd"),
  password: Yup.string().required("Passord er påkrevd"),
});

const Login: React.FC = () => {
  const { execute, error, isLoading } = useFetch<any>();
  const navigate = useNavigate();
  const {
    setOrgNumber,
    setUid,
    setIsSuperAdmin,
    setIsMawaqeetAdmin,
    setSelectedOrgNumber,
  } = useAuth();
  const [generalError, setGeneralError] = useState<string | null>(null);

  const handleLogin = async (
    values: { username: string; password: string },
    setError: (errorMessage: string) => void,
  ) => {
    try {
      const email = values.username.trim();
      const password = values.password.trim();

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const idToken = await userCredential.user.getIdToken();

      sessionStorage.setItem("token", idToken);

      const data = await execute("/api/v1/auth/verifyToken", "POST", { email });

      if (data && data.allowedOperations) {
        const { uid, allowedOperations } = data;
        const isSuperAdmin =
          allowedOperations === "vdfJ3fd32NibBHJ4732JNFAQCMCm38qcm34c49m";

        const isMawaqeetAdmin =
          allowedOperations === "MAWAQEETADMIN6328xkeowkgj63827481";

        setUid(uid);
        setOrgNumber(allowedOperations);
        setIsSuperAdmin(isSuperAdmin);
        setIsMawaqeetAdmin(isMawaqeetAdmin);
        setSelectedOrgNumber(allowedOperations);

        // Save details to session storage
        sessionStorage.setItem("uid", uid);
        sessionStorage.setItem("orgNumber", allowedOperations);
        sessionStorage.setItem("selectedOrgNumber", allowedOperations);
        sessionStorage.setItem("isSuperAdmin", JSON.stringify(isSuperAdmin));
        sessionStorage.setItem(
          "isMawaqeetAdmin",
          JSON.stringify(isMawaqeetAdmin),
        );

        console.log("User has privileges");

        // Redirect based on super admin status and selected mosque
        if (isSuperAdmin) {
          navigate("/velg-moske");
        } else {
          navigate("/");
        }
      } else {
        console.log("User does not have privileges");
        setError("User does not have privileges");
      }
    } catch (err: any) {
      const errorCode = err?.code || "unknown";
      const friendlyMessage = getFriendlyErrorMessage(errorCode);
      setError(friendlyMessage);
    }
  };

  const getFriendlyErrorMessage = (errorCode: string) => {
    switch (errorCode) {
      case "auth/invalid-email":
        return "Please enter a valid email.";
      case "auth/invalid-password":
        return "Incorrect password. Please try again.";
      case "auth/invalid-credential":
        return "Invalid email or password. Please check your credentials and try again.";
      case "auth/user-not-found":
        return "No account found with this email address.";
      case "auth/too-many-requests":
        return "Too many failed login attempts. Please try again later.";
      default:
        return "An error occurred. Please try again.";
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.leftSection}>
        <img
          src={manAndWife}
          alt="Muslim man and wife praying"
          className="img-fluid"
        />
      </div>
      <div className={styles.rightSection}>
        <Icon className={styles.icon} />
        <hr />
        <h1 className="mb-5">Velkommen til iBønn Admin</h1>
        {isLoading ? (
          <Loading size="large" variant="primary" message="Logger inn..." />
        ) : (
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleLogin(values, (errorMessage: string) => {
                setGeneralError(errorMessage);
                setSubmitting(false);
              });
            }}
          >
            {({ isSubmitting }) => (
              <Form className="w-75">
                <div className={styles.formControl}>
                  <label htmlFor="username" className={styles.formLabel}>
                    Brukernavn
                  </label>
                  <Field type="text" name="username" className="form-control" />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className={styles.errorMessage}
                  />
                </div>
                <div className={styles.formControl}>
                  <label htmlFor="password" className={styles.formLabel}>
                    Passord
                  </label>
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className={styles.errorMessage}
                  />
                </div>

                <button
                  type="submit"
                  className={`btn btn-primary ${styles.btnPrimary}`}
                  disabled={isSubmitting || isLoading}
                >
                  Logg inn
                </button>

                {generalError ? (
                  <div className={styles.errorMessage}>{generalError}</div>
                ) : error ? (
                  <div className={styles.errorMessage}>
                    {error.text || "En feil oppstod"}
                  </div>
                ) : null}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default Login;
