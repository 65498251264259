// src/components/Header.tsx
import React from "react";
import styles from "./Header.module.scss";

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <header className={styles.pageHeader}>
      <h1 className={styles.pageHeaderTitle}>{title}</h1>
    </header>
  );
};

export default Header;
