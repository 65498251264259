import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal, Pagination } from "react-bootstrap";
import useFetch from "hooks/useFetch";
import styles from "./HijriPage.module.scss";

interface SpecialDate {
  id: number;
  gregorianDate: string;
  hijriMonth: string;
  hijriDate: string;
  specialDateName: string;
}

const HijriPage: React.FC = () => {
  const { execute } = useFetch();
  const [specialDates, setSpecialDates] = useState<SpecialDate[]>([]);
  const [filteredDates, setFilteredDates] = useState<SpecialDate[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState<SpecialDate | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedHijriMonth, setSelectedHijriMonth] = useState("");

  useEffect(() => {
    const fetchDates = async () => {
      const dateResult = (await execute(
        "/api/v1/dates/special/2024",
        "GET",
      )) as SpecialDate[];
      if (dateResult) setSpecialDates(dateResult);
    };
    fetchDates();
  }, []);

  const uniqueHijriMonths = Array.from(
    new Set(specialDates.map((date) => date.hijriMonth)),
  );

  useEffect(() => {
    const filtered = specialDates.filter((date) => {
      const matchesSearch = date.specialDateName
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesMonth =
        selectedHijriMonth === "" || date.hijriMonth === selectedHijriMonth;
      return matchesSearch && matchesMonth;
    });
    setFilteredDates(filtered);
    setCurrentPage(1);
  }, [searchTerm, selectedHijriMonth, specialDates]);

  const handleEditClick = (date: SpecialDate) => {
    setSelectedDate(date);
  };

  const handleSaveChanges = async () => {
    if (selectedDate) {
      await execute(
        `/api/v1/dates/special/${selectedDate.id}`,
        "PATCH",
        selectedDate,
      );
      setSelectedDate(null);
      alert("Special date updated!");
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (selectedDate) {
      const { name, value } = e.target;
      setSelectedDate((prev) => (prev ? { ...prev, [name]: value } : prev));
    }
  };

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDates.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="container mt-4">
      <h1>Hijri Spesielle Datoer</h1>
      <hr />
      {/* Search bar */}
      <Form.Control
        type="text"
        placeholder="Søk etter hijri navn..."
        className="mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Hijri Month Filter */}
      <Form.Select
        className="mb-4"
        value={selectedHijriMonth}
        onChange={(e) => setSelectedHijriMonth(e.target.value)}
      >
        <option value="">Filter etter Hijri Måned</option>
        {uniqueHijriMonths.map((month) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </Form.Select>

      {/* Special Date List */}
      <div className="special-date-list">
        {currentItems.map((date) => (
          <Card key={date.id} className="mb-3">
            <Card.Header className={styles.cardHeader}>
              <strong>{date.specialDateName}</strong> {date.hijriMonth}
            </Card.Header>
            <Card.Body>
              <p>
                <strong>Gregorisk dato:</strong> {date.gregorianDate}
              </p>
              <p>
                <strong>Hijri dato:</strong> {date.hijriDate}
              </p>
              {/* <Button
                variant="primary"
                onClick={() => handleEditClick(date)}
                disabled
              >
                Rediger
              </Button> */}
              {/*PATCH is not ready so this button is temporarily commented out*/}
            </Card.Body>
          </Card>
        ))}
      </div>

      {/* Pagination */}
      <Pagination>
        {Array.from(
          { length: Math.ceil(filteredDates.length / itemsPerPage) },
          (_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ),
        )}
      </Pagination>

      {/* Edit Modal */}
      <Modal
        show={!!selectedDate}
        onHide={() => setSelectedDate(null)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Special Date</Modal.Title>
        </Modal.Header>
        {selectedDate && (
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Gregorian Date</Form.Label>
                <Form.Control
                  type="date"
                  name="gregorianDate"
                  value={selectedDate.gregorianDate}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Hijri Date</Form.Label>
                <Form.Control
                  type="text"
                  name="hijriDate"
                  value={selectedDate.hijriDate}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Special Date Name</Form.Label>
                <Form.Control
                  type="text"
                  name="specialDateName"
                  value={selectedDate.specialDateName}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedDate(null)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HijriPage;
