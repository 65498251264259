import React from "react";
import styles from "./AdminPageSkeleton.module.scss";

const AdminPageSkeleton: React.FC = () => {
  return (
    <div className={`container ${styles.skeletonContainer}`}>
      <h2 className={styles.skeletonHeader}></h2>

      <div className={styles.skeletonCard}>
        <div className={styles.skeletonHeader}></div>
        <div className={styles.skeletonInput}></div>
        <div className={styles.skeletonInput}></div>
      </div>

      <div className={styles.skeletonCard}>
        <div className={styles.skeletonHeader}></div>
        <div className={styles.skeletonLogo}></div>
        <div className={styles.skeletonInput}></div>
        <div className={styles.skeletonInput}></div>
        <div className={styles.skeletonInput}></div>
        <div className={styles.skeletonInput}></div>
      </div>

      <div className={styles.skeletonCard}>
        <div className={styles.skeletonHeader}></div>
        <div className={styles.skeletonInput}></div>
        <div className={styles.skeletonInput}></div>
        <div className={styles.skeletonInput}></div>
      </div>

      <div className={styles.skeletonButton}></div>
    </div>
  );
};

export default AdminPageSkeleton;
