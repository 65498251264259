import { ReactComponent as MenCapacityIcon } from "../../assets/MosqueIcons/10 Men capacity.svg";
import { ReactComponent as WomenCapacityIcon } from "../../assets/MosqueIcons/8 Women capacity.svg";
import { ReactComponent as BarnaMadrassaIcon } from "../../assets/MosqueIcons/Barna Madrassa.svg";
import { ReactComponent as CounselingIcon } from "../../assets/MosqueIcons/Counseling.svg";
import { ReactComponent as CoursesForWomenIcon } from "../../assets/MosqueIcons/Courses for women.svg";
import { ReactComponent as EidIcon } from "../../assets/MosqueIcons/Eid.svg";
import { ReactComponent as HallHireIcon } from "../../assets/MosqueIcons/Hall hire.svg";
import { ReactComponent as JanazahIcon } from "../../assets/MosqueIcons/Janazah.svg";
import { ReactComponent as JummahPrayerIcon } from "../../assets/MosqueIcons/Jummah prayer.svg";
import { ReactComponent as KitchenIcon } from "../../assets/MosqueIcons/Kitchen.svg";
import { ReactComponent as NikkahServicesIcon } from "../../assets/MosqueIcons/Nikkah services.svg";
import { ReactComponent as ParkingIcon } from "../../assets/MosqueIcons/Parking.svg";
import { ReactComponent as RamadanIftarIcon } from "../../assets/MosqueIcons/Ramadan iftar.svg";
import { ReactComponent as SupportNewMuslimsIcon } from "../../assets/MosqueIcons/Support new muslims.svg";
import { ReactComponent as TaraweehIcon } from "../../assets/MosqueIcons/Taraweeh.svg";
import { ReactComponent as ToiletMenIcon } from "../../assets/MosqueIcons/Toilet Men.svg";
import { ReactComponent as ToiletWomenIcon } from "../../assets/MosqueIcons/Toilet Women.svg";
import { ReactComponent as VoksneMadrassaIcon } from "../../assets/MosqueIcons/Voksne Madrassa.svg";
import { ReactComponent as WuduMenIcon } from "../../assets/MosqueIcons/Wudu Men.svg";
import { ReactComponent as WuduWomenIcon } from "../../assets/MosqueIcons/Wudu Women.svg";

const icons = [
  { name: "Kapasitet Kvinner", component: WomenCapacityIcon, type: "capacity" },
  { name: "Kapasitet Menn", component: MenCapacityIcon, type: "capacity" },
  { name: "Barne Madrassa", component: BarnaMadrassaIcon, type: "toggle" },
  { name: "Rådgivning", component: CounselingIcon, type: "toggle" },
  { name: "Kurs for kvinner", component: CoursesForWomenIcon, type: "toggle" },
  { name: "Leie hall", component: HallHireIcon, type: "toggle" },
  { name: "Kjøkken", component: KitchenIcon, type: "toggle" },
  { name: "Voksen Madrassa", component: VoksneMadrassaIcon, type: "toggle" },
  { name: "Parkering", component: ParkingIcon, type: "toggle" },
  {
    name: "Støtte ny-muslimer",
    component: SupportNewMuslimsIcon,
    type: "toggle",
  },
  { name: "Nikkah tjenester", component: NikkahServicesIcon, type: "toggle" },
  { name: "Taraweeh", component: TaraweehIcon, type: "toggle" },
  { name: "Jummah bønn", component: JummahPrayerIcon, type: "toggle" },
  { name: "Eid", component: EidIcon, type: "toggle" },
  { name: "Ramadan iftar", component: RamadanIftarIcon, type: "toggle" },
  { name: "Janazah", component: JanazahIcon, type: "toggle" },
  { name: "Toaletter Menn", component: ToiletMenIcon, type: "toggle" },
  { name: "Toaletter Kvinner", component: ToiletWomenIcon, type: "toggle" },
  { name: "Wudu Menn", component: WuduMenIcon, type: "toggle" },
  { name: "Wudu Kvinner", component: WuduWomenIcon, type: "toggle" },
];

export default icons;
