import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "AuthProvider";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { Button, Card, Tab, Tabs } from "react-bootstrap";
import Header from "stories/Header/Header";
import NoContentPlaceholder from "stories/NoContentPlaceholder/NoContentPlaceholder";
import AddSpecialTimeModal from "./AddSpecialTimeModal/AddSpecialTimeModal";
import styles from "./SpecialPrayerSettings.module.scss";

interface TimeSection {
  id: number;
  time: string;
  info: string;
  active: boolean;
  expanded: boolean;
  date: string;
  startDate: string;
  endDate: string;
}

const SpecialPrayerSettings = () => {
  const { execute, isLoading, error } = useFetch<any>();
  const { orgNumber, selectedOrgNumber } = useAuth();
  const [activeTab, setActiveTab] = useState<string>("jummah");
  const [jummahTimes, setJummahTimes] = useState<TimeSection[]>([]);
  const [eidTimes, setEidTimes] = useState<TimeSection[]>([]);
  const [taraweehTimes, setTaraweehTimes] = useState<TimeSection[]>([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchTimes = async (
      endpoint: string,
      setTimes: React.Dispatch<React.SetStateAction<TimeSection[]>>,
      timeKey: string,
    ) => {
      const response = await execute(endpoint, "GET");
      if (response) {
        setTimes(
          response.map((timeData: any) => ({
            id: timeData.id,
            time: timeData[timeKey],
            info: timeData.info,
            date: timeData.date,
            active: false,
            expanded: false,
            startDate: timeData.startDate,
            endDate: timeData.endDate,
          })),
        );
      }
    };

    const fetchAllTimes = async () => {
      await Promise.all([
        fetchTimes(
          `/api/v1/jummah/org/${selectedOrgNumber}`,
          setJummahTimes,
          "jummahTime",
        ),
        fetchTimes(
          `/api/v1/taraweeh/org/${selectedOrgNumber}`,
          setTaraweehTimes,
          "taraweehTime",
        ),
        fetchTimes(
          `/api/v1/eid/org/${selectedOrgNumber}`,
          setEidTimes,
          "eidTime",
        ),
      ]);
    };

    fetchAllTimes();
  }, [selectedOrgNumber]);

  const handleToggle = (
    setTimes: React.Dispatch<React.SetStateAction<TimeSection[]>>,
    index: number,
  ) => {
    setTimes((prevTimes) =>
      prevTimes.map((time, i) => ({
        ...time,
        active: i === index,
        expanded: i === index,
      })),
    );
  };

  const handleAddNewTime = () => {
    setShowModal(true);
  };

  const handleSaveNewTime = async (
    time: string,
    info: string,
    startDate: string,
    endDate: string,
  ) => {
    if (!startDate || !endDate) {
      alert("Startdato og sluttdato må være satt.");
      return;
    }

    const newDate = new Date().toISOString();
    let payload: any = {
      info,
      mosqueOrgNr: selectedOrgNumber,
      date: newDate,
      startDate,
      endDate,
    };

    if (activeTab === "jummah") {
      payload.jummahTime = time;
    } else if (activeTab === "taraweeh") {
      payload.taraweehTime = time;
    } else if (activeTab === "eid") {
      payload.eidTime = time;
    }

    const endpoint =
      activeTab === "jummah"
        ? "/api/v1/jummah"
        : activeTab === "taraweeh"
          ? "/api/v1/taraweeh"
          : "/api/v1/eid";

    const response = await execute(endpoint, "POST", payload);
    if (response) {
      const newTimeSection: TimeSection = {
        id: response.id,
        time,
        info,
        active: false,
        expanded: true,
        date: newDate,
        startDate,
        endDate,
      };

      if (activeTab === "jummah") {
        setJummahTimes((prevTimes) => [...prevTimes, newTimeSection]);
      } else if (activeTab === "taraweeh") {
        setTaraweehTimes((prevTimes) => [...prevTimes, newTimeSection]);
      } else if (activeTab === "eid") {
        setEidTimes((prevTimes) => [...prevTimes, newTimeSection]);
      }
    }
  };

  const handleDeleteTime = (id: number) => {
    let endpoint = "";

    if (activeTab === "jummah") {
      endpoint = `/api/v1/jummah/${id}`;
    } else if (activeTab === "taraweeh") {
      endpoint = `/api/v1/taraweeh/${id}`;
    } else if (activeTab === "eid") {
      endpoint = `/api/v1/eid/${id}`;
    }

    execute(endpoint, "DELETE")
      .then(() => {
        if (activeTab === "jummah") {
          const updatedTimes = jummahTimes.filter((time) => time.id !== id);
          setJummahTimes(updatedTimes);
        } else if (activeTab === "taraweeh") {
          const updatedTimes = taraweehTimes.filter((time) => time.id !== id);
          setTaraweehTimes(updatedTimes);
        } else if (activeTab === "eid") {
          const updatedTimes = eidTimes.filter((time) => time.id !== id);
          setEidTimes(updatedTimes);
        }
      })
      .catch((error) => {
        console.error("Error deleting time:", error);
      });
  };

  const renderTimeSections = (
    times: TimeSection[],
    setTimes: React.Dispatch<React.SetStateAction<TimeSection[]>>,
    title: string,
  ) => {
    if (times.length === 0) {
      return <NoContentPlaceholder message={`Ingen ${title} tilgjengelig.`} />;
    }

    return times.map((time, index) => (
      <Card key={time.id} className="mb-3">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <div>
                <span
                  role="img"
                  aria-label="prayer"
                  style={{ marginRight: "10px" }}
                >
                  🕌
                </span>
                {`${title} ${index + 1}`}
              </div>
              <div className="mt-2">
                <strong>{time.time}</strong>
              </div>
              <div className="mt-2">
                <p>{time.info}</p>
                {title === "Jummah tid" && (
                  <>
                    <p>{`Start dato: ${time.startDate}`}</p>{" "}
                    <p>{`Slutt dato: ${time.endDate}`}</p>{" "}
                  </>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center gap-4 custom-form">
              <Button
                variant="danger"
                onClick={() => handleDeleteTime(time.id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    ));
  };

  return (
    <div className="container">
      <Header title="Spesielle bønnetider" />
      <br />
      <Tabs
        defaultActiveKey="jummah"
        className="mb-3"
        onSelect={(k) => setActiveTab(k!)}
      >
        <Tab eventKey="jummah" title="Jummah">
          {renderTimeSections(jummahTimes, setJummahTimes, "Jummah tid")}
          <Button
            variant="dark"
            type="submit"
            className={styles.largeButton}
            onClick={handleAddNewTime}
          >
            Legg til ny Jummah tid{" "}
          </Button>
        </Tab>

        <Tab eventKey="taraweeh" title="Taraweeh">
          {renderTimeSections(taraweehTimes, setTaraweehTimes, "Taraweeh tid")}
          <Button
            variant="dark"
            type="submit"
            className={styles.largeButton}
            onClick={handleAddNewTime}
          >
            Legg til ny Taraweeh tid
          </Button>
        </Tab>

        <Tab eventKey="eid" title="Eid">
          {renderTimeSections(eidTimes, setEidTimes, "Eid tid")}
          <Button
            variant="dark"
            type="submit"
            className={styles.largeButton}
            onClick={handleAddNewTime}
          >
            Legg til ny Eid tid
          </Button>
        </Tab>
      </Tabs>

      <AddSpecialTimeModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSave={handleSaveNewTime}
        activeTab={activeTab}
      />

      {isLoading && <div>Loading...</div>}
      {error && (
        <div className="text-danger mt-2">
          Error: {error.text || "An error occurred"}
        </div>
      )}
    </div>
  );
};

export default SpecialPrayerSettings;
