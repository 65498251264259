import {
  faEdit,
  faTrash,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "AuthProvider";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import useFetch from "hooks/useFetch";
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import styles from "./BlogPage.module.scss";
import NoContentPlaceholder from "stories/NoContentPlaceholder/NoContentPlaceholder";

interface BlogForm {
  tittel: string;
  tekst: string;
  plakat?: File | null;
  activated: boolean;
}

interface Blog {
  id: number;
  tittel: string;
  tekst: string;
  date: string;
  plakat?: string;
  activated: boolean;
}

const validationSchema = Yup.object().shape({
  tittel: Yup.string().required("Blogg overskrift er påkrevd"),
  tekst: Yup.string().required("Blogg innhold er påkrevd"),
  plakat: Yup.mixed(),
});

const BlogPage: React.FC = () => {
  const { selectedOrgNumber } = useAuth();
  const { data, isLoading, error, execute } = useFetch<any>();
  const {
    data: blogsData,
    isLoading: isBlogsLoading,
    execute: fetchBlogs,
  } = useFetch<Blog[]>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [editImagePreview, setEditImagePreview] = useState<string | null>(null);
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editingBlog, setEditingBlog] = useState<Blog | null>(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageToShow, setImageToShow] = useState<string | null>(null);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleImageClick = (image: string) => {
    setImageToShow(image);
    setShowImageModal(true);
  };

  const fetchBlogList = async () => {
    const response = await fetchBlogs(
      `/api/v1/blogPost/org/${selectedOrgNumber}`,
      "GET",
    );
    if (response) {
      setBlogs(response);
    }
  };

  useEffect(() => {
    fetchBlogList();
  }, [selectedOrgNumber]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (values: BlogForm, { resetForm }: any) => {
    if (!selectedDate) {
      setDateError("Dato er påkrevd");
      return;
    } else {
      setDateError(null);
    }

    const currentTime = new Date();
    const hours = String(currentTime.getHours()).padStart(2, "0");
    const minutes = String(currentTime.getMinutes()).padStart(2, "0");

    const formattedDate = `${selectedDate.getFullYear()}-${String(
      selectedDate.getMonth() + 1,
    ).padStart(
      2,
      "0",
    )}-${String(selectedDate.getDate()).padStart(2, "0")} ${hours}:${minutes}`;

    const blogRequestDto = {
      date: formattedDate,
      tittel: values.tittel,
      tekst: values.tekst,
      mosqueOrgNr: selectedOrgNumber,
      activated: values.activated,
      plakat: "",
    };

    const formData = new FormData();
    if (values.plakat) {
      formData.append("file", values.plakat);
    }
    formData.append("blogPostRequestDto", JSON.stringify(blogRequestDto));

    try {
      const response = await execute(
        "/api/v1/blogPost/addWithImage",
        "POST",
        formData,
      );
      resetForm();
      setSelectedDate(null);
      setImagePreview(null);
      setBlogs((prevBlogs) => [...prevBlogs, response]);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      fetchBlogList();
    } catch (error: any) {
      console.error("Error:", error.response || error.message || error);
    }
  };

  const deleteBlog = async (id: number) => {
    try {
      await execute(`/api/v1/blogPost/${id}`, "DELETE");
      setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.id !== id));
    } catch (error: any) {
      console.error("Failed to delete blog", error);
    }
  };

  const openEditModal = (blog: Blog) => {
    setEditingBlog(blog);
    setShowModal(true);
    setEditImagePreview(blog.plakat || null);
  };

  const handleEditFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    if (file) {
      setEditImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSaveEdit = async (values: BlogForm) => {
    if (!editingBlog) return;

    const currentTime = new Date();
    const hours = String(currentTime.getHours()).padStart(2, "0");
    const minutes = String(currentTime.getMinutes()).padStart(2, "0");

    const formattedDate = `${currentTime.getFullYear()}-${String(
      currentTime.getMonth() + 1,
    ).padStart(
      2,
      "0",
    )}-${String(currentTime.getDate()).padStart(2, "0")} ${hours}:${minutes}`;

    const blogRequestDto = {
      date: formattedDate,
      tittel: values.tittel,
      tekst: values.tekst,
      plakat: editImagePreview || editingBlog.plakat || "",
      mosqueOrgNr: selectedOrgNumber,
      activated: values.activated,
    };

    const formData = new FormData();
    formData.append("blogPostRequestDto", JSON.stringify(blogRequestDto));
    if (values.plakat) {
      formData.append("file", values.plakat);
    }

    try {
      await execute(`/api/v1/blogPost/${editingBlog.id}`, "PATCH", formData);
      setBlogs((prevBlogs) =>
        prevBlogs.map((blog) =>
          blog.id === editingBlog.id
            ? {
                ...blog,
                tittel: values.tittel,
                tekst: values.tekst,
                activated: values.activated,
                plakat: editImagePreview || blog.plakat || "",
                date: formattedDate,
              }
            : blog,
        ),
      );

      setShowModal(false);
      setEditingBlog(null);
      setEditImagePreview(null);
    } catch (error) {
      console.error("Failed to update blog", error);
    }
  };

  return (
    <div className={styles.blogPageContainer}>
      <Card className={styles.blogCard}>
        <Card.Body>
          <h2 className="text-center">Opprett Blogg</h2>

          <Formik
            initialValues={{
              tittel: "",
              tekst: "",
              plakat: null,
              activated: true,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty, setFieldValue, values }) => (
              <FormikForm>
                <Form.Group controlId="formTittel" className={styles.formGroup}>
                  <Form.Label>Blogg overskrift*</Form.Label>
                  <Field
                    name="tittel"
                    className="form-control"
                    placeholder="Skriv blogg overskrift"
                  />
                  <ErrorMessage
                    name="tittel"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group controlId="formTekst" className={styles.formGroup}>
                  <Form.Label>Blogg innhold*</Form.Label>
                  <Field
                    name="tekst"
                    as="textarea"
                    className="form-control"
                    rows={5}
                    placeholder="Skriv blogg innhold"
                  />
                  <ErrorMessage
                    name="tekst"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group
                  controlId="formDate"
                  className={styles.datePickerGroup}
                >
                  <Form.Label>Dato*</Form.Label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                  {dateError && <div className="text-danger">{dateError}</div>}
                </Form.Group>

                <Form.Group controlId="formPlakat" className={styles.formGroup}>
                  <Form.Label>Overskriftsbilde*</Form.Label>
                  <input
                    name="plakat"
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onChange={(event) => {
                      setFieldValue(
                        "plakat",
                        event.currentTarget.files?.[0] || null,
                      );
                      handleFileChange(event);
                    }}
                    ref={fileInputRef}
                  />
                  {imagePreview && (
                    <div className={styles.imagePreview}>
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className="img-fluid"
                      />
                    </div>
                  )}
                </Form.Group>

                <Form.Group controlId="formActivated">
                  <Form.Check
                    type="switch"
                    label="Vis dette i appen"
                    name="activated"
                    checked={values.activated}
                    onChange={(e) =>
                      setFieldValue("activated", e.target.checked)
                    }
                  />
                </Form.Group>

                {isLoading ? (
                  <Button variant="primary" disabled>
                    Laster opp...
                  </Button>
                ) : (
                  <Button
                    variant="dark"
                    type="submit"
                    className={styles.largeButton}
                    disabled={
                      !(isValid && dirty && selectedDate && values.plakat)
                    }
                  >
                    Lagre Blogg
                  </Button>
                )}

                {error && (
                  <Alert variant="danger" className="mt-3">
                    Error: {error.text}
                  </Alert>
                )}
                {data && (
                  <Alert variant="success" className="mt-3">
                    Blogg opprettet!
                  </Alert>
                )}
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <h3>Alle Blogger</h3>
      <div>
        {isBlogsLoading ? (
          <Spinner animation="border" />
        ) : error ? (
          <Alert variant="danger">Feil under lasting av blogger</Alert>
        ) : (
          <div className={styles.blogList}>
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <div key={blog.id} className={styles.blogItem}>
                  {blog.plakat && (
                    <div className={styles.blogPlakat}>
                      <img
                        src={blog.plakat}
                        alt={blog.tittel}
                        onClick={() =>
                          blog.plakat && handleImageClick(blog.plakat)
                        }
                      />
                    </div>
                  )}
                  <div className={styles.blogContent}>
                    <h3 className={styles.blogTitleWithIcon}>{blog.tittel}</h3>
                    <p>
                      {blog.tekst.length > 100
                        ? `${blog.tekst.substring(0, 100)}...`
                        : blog.tekst}
                    </p>
                    <p>
                      <strong>Dato:</strong>{" "}
                      {new Date(blog.date).toLocaleDateString()}
                    </p>
                  </div>
                  <div className={styles.footerContent}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          {blog.activated ? "Vises i appen" : "Skjult i appen"}
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon={blog.activated ? faEye : faEyeSlash}
                        style={{
                          marginLeft: "8px",
                        }}
                      />
                    </OverlayTrigger>
                    <div className={styles.blogActions}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => openEditModal(blog)}
                        className={styles.editIcon}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => deleteBlog(blog.id)}
                        className={styles.deleteIcon}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NoContentPlaceholder message="Ingen blogger å vise." />
            )}

            <Modal
              show={showImageModal}
              onHide={() => setShowImageModal(false)}
              centered
            >
              <Modal.Body>
                {imageToShow && (
                  <img
                    src={imageToShow}
                    alt="Full Image"
                    className="img-fluid"
                  />
                )}
              </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>Rediger Blogg</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {editingBlog && (
                  <Formik
                    initialValues={{
                      tittel: editingBlog.tittel,
                      tekst: editingBlog.tekst,
                      activated: editingBlog.activated,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSaveEdit}
                  >
                    {({ isValid, values, setFieldValue }) => (
                      <FormikForm>
                        <Form.Group
                          controlId="formTittel"
                          className={styles.formGroup}
                        >
                          <Form.Label>Tittel*</Form.Label>
                          <Field
                            name="tittel"
                            className="form-control"
                            placeholder="Rediger blogg overskrift"
                          />
                          <ErrorMessage
                            name="tittel"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>

                        <Form.Group
                          controlId="formTekst"
                          className={styles.formGroup}
                        >
                          <Form.Label>Tekst*</Form.Label>
                          <Field
                            name="tekst"
                            as="textarea"
                            className="form-control"
                            rows={5}
                            placeholder="Rediger blogg innhold"
                          />
                          <ErrorMessage
                            name="tekst"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>

                        <Form.Group controlId="formActivated">
                          <Form.Check
                            type="switch"
                            label="Vis dette i appen"
                            name="activated"
                            checked={values.activated}
                            onChange={(e) =>
                              setFieldValue("activated", e.target.checked)
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          controlId="editImage"
                          className={styles.formGroup}
                        >
                          <Form.Label>Endre Bilde</Form.Label>
                          <input
                            name="plakat"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            onChange={(event) => {
                              setFieldValue(
                                "plakat",
                                event.currentTarget.files?.[0] || null,
                              );
                              handleEditFileChange(event);
                            }}
                          />
                          {editImagePreview && (
                            <div className={styles.imagePreview}>
                              <img
                                src={editImagePreview}
                                alt="Edit Preview"
                                className="img-fluid"
                              />
                            </div>
                          )}
                        </Form.Group>

                        <Button
                          variant="dark"
                          type="submit"
                          className={styles.largeButton}
                          disabled={!isValid}
                        >
                          Lagre Endringer
                        </Button>
                      </FormikForm>
                    )}
                  </Formik>
                )}
              </Modal.Body>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPage;
