import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Button } from "stories";

interface OffsetModalProps {
  show: boolean;
  formattedDate: string;
  times: {
    fajrJamatOffset?: number;
    duhrJamatOffset?: number;
    asrJamatOffset?: number;
    maghribJamatOffset?: number;
    ishaJamatOffset?: number;
  };
  dateKey: string;
  onInputChange: (
    dateKey: string,
    offset: keyof OffsetModalProps["times"],
    value: number,
  ) => void;
  onSave: (dateKey: string) => void;
  onClose: () => void;
  fetchLatestPrayerTimes: () => void;
}

const OffsetModal: React.FC<OffsetModalProps> = ({
  show,
  formattedDate,
  times,
  dateKey,
  onInputChange,
  onSave,
  onClose,
  fetchLatestPrayerTimes,
}) => {
  const [checkboxState, setCheckboxState] = useState({
    fajrJamatOffset: true,
    duhrJamatOffset: true,
    asrJamatOffset: true,
    maghribJamatOffset: true,
    ishaJamatOffset: true,
  });

  useEffect(() => {
    setCheckboxState({
      fajrJamatOffset:
        times.fajrJamatOffset === -1 || times.fajrJamatOffset == null,
      duhrJamatOffset:
        times.duhrJamatOffset === -1 || times.duhrJamatOffset == null,
      asrJamatOffset:
        times.asrJamatOffset === -1 || times.asrJamatOffset == null,
      maghribJamatOffset:
        times.maghribJamatOffset === -1 || times.maghribJamatOffset == null,
      ishaJamatOffset:
        times.ishaJamatOffset === -1 || times.ishaJamatOffset == null,
    });
  }, [times]);

  const handleCheckboxChange = (
    offset: keyof typeof checkboxState,
    checked: boolean,
  ) => {
    setCheckboxState((prevState) => ({ ...prevState, [offset]: checked }));
    if (checked) {
      // Deactivate offset
      onInputChange(dateKey, offset, -1);
    } else {
      // Activate offset, default to 0 if undefined
      const currentValue = times[offset] === -1 ? 0 : times[offset] || 0;
      onInputChange(dateKey, offset, currentValue);
    }
  };

  const handleSaveClick = () => {
    onSave(dateKey);
    fetchLatestPrayerTimes();
    onClose();
  };

  const getLabelName = (offsetKey: string) => {
    const prayerName = offsetKey.replace("JamatOffset", "");
    return prayerName.charAt(0).toUpperCase() + prayerName.slice(1);
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Bruk Offsets for {formattedDate}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {[
            "fajrJamatOffset",
            "duhrJamatOffset",
            "asrJamatOffset",
            "maghribJamatOffset",
            "ishaJamatOffset",
          ].map((offsetKey) => (
            <Form.Group controlId={offsetKey} key={offsetKey}>
              <Form.Label>
                {getLabelName(offsetKey)} Offset (
                {checkboxState[offsetKey as keyof typeof checkboxState]
                  ? "Deaktivert"
                  : `+${times[offsetKey as keyof typeof times] || 0}`}
                )
              </Form.Label>
              <Form.Check
                type="checkbox"
                label="Deaktiver offset"
                checked={checkboxState[offsetKey as keyof typeof checkboxState]}
                onChange={(e) =>
                  handleCheckboxChange(
                    offsetKey as keyof typeof checkboxState,
                    e.target.checked,
                  )
                }
              />
              <Form.Range
                min={0}
                max={90}
                step={5}
                value={
                  checkboxState[offsetKey as keyof typeof checkboxState]
                    ? 0
                    : times[offsetKey as keyof typeof times] || 0
                }
                disabled={
                  checkboxState[offsetKey as keyof typeof checkboxState]
                }
                onChange={(e) =>
                  onInputChange(
                    dateKey,
                    offsetKey as keyof typeof times,
                    parseInt(e.target.value, 10),
                  )
                }
              />
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSaveClick} label="Lagre" />
        <Button variant="error" onClick={onClose} label="Avbryt" />
      </Modal.Footer>
    </Modal>
  );
};

export default OffsetModal;