import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import styles from "./NoContentPlaceholder.module.scss";

interface NoContentPlaceholderProps {
  message?: string;
  variant?: "default" | "compact";
}

const NoContentPlaceholder: React.FC<NoContentPlaceholderProps> = ({
  message = "Ingen poster å vise",
  variant = "default",
}) => {
  return (
    <Card
      className={`${styles.noContentCard} ${
        variant === "compact" ? styles.compact : ""
      }`}
    >
      <Card.Body className="text-center">
        <FontAwesomeIcon
          icon={faInbox}
          size={variant === "compact" ? "2x" : "3x"}
          className="mb-2"
        />
        <h5 className={variant === "compact" ? styles.compactText : ""}>
          {message}
        </h5>
      </Card.Body>
    </Card>
  );
};

export default NoContentPlaceholder;
