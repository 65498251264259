export enum AsrMethod {
  NONE = "NONE",
  MIXED = "MIXED",
  ONE_X = "ONE_X",
  TWO_X = "TWO_X",
}

export enum IconState {
  MadrassahChildren = "madrassahChildren",
  Advice = "advice",
  DarsWomen = "darsWomen",
  Eid = "eid",
  HallHire = "hallHire",
  Janazah = "janazah",
  Jummah = "jummah",
  Kitchen = "kitchen",
  Nikah = "nikah",
  Parking = "parking",
  Iftar = "iftar",
  NewMuslim = "newMuslim",
  Taraweeh = "taraweeh",
  ToiletMen = "toiletMen",
  ToiletWomen = "toiletWomen",
  MadrassahAdult = "madrassahAdult",
  AblutionMen = "ablutionMen",
  AblutionWomen = "ablutionWomen",
}
