import React, { useEffect, useState } from "react";
import { Button, Card, Form, Alert } from "react-bootstrap";
import { ErrorMessage, Field, Form as FormikForm, Formik } from "formik";
import Select, { SingleValue } from "react-select";
import useFetch from "hooks/useFetch";
import * as Yup from "yup";
import styles from "./CreateMosqueRequestPage.module.scss";
import iBonnLogo from "assets/ibonn-icon.svg";
import { AsrMethod } from "enums/AdminPageEnums";

interface SelectOption {
  value: number;
  label: string;
}

interface MosqueRequestFormValues {
  orgNr: string;
  name: string;
  orgName: string;
  streetAddress: string;
  postalCode: string;
  city: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  locationId?: number;
  prayerTimeTable?: number;
  disclaimerAccepted: boolean;
  brregConfirmed: boolean;
  asrMethod: AsrMethod;
}

interface Location {
  id: number;
  name: string;
  prayerTimesTables: Array<{ id: number; name: string }>;
}

const validationSchema = Yup.object({
  orgNr: Yup.string().required("Organisasjonsnummer er påkrevd"),
  name: Yup.string().required("Moské navn er påkrevd"),
  orgName: Yup.string().required("Organisasjonsnavn er påkrevd"),
  streetAddress: Yup.string().required("Adresse er påkrevd"),
  postalCode: Yup.string().required("Postnummer er påkrevd"),
  city: Yup.string().required("Sted er påkrevd"),
  contactName: Yup.string().required("Kontaktperson er påkrevd"),
  contactPhone: Yup.number().required("Telefonnummer er påkrevd"),
  contactEmail: Yup.string()
    .email("Ugyldig e-post")
    .required("E-post er påkrevd"),
  locationId: Yup.number()
    .required("Lokasjon er påkrevd")
    .min(1, "Velg en gyldig lokasjon"),
  prayerTimeTable: Yup.number()
    .required("Bønnetid Tabell er påkrevd")
    .min(1, "Velg en gyldig tidstabell"),
  disclaimerAccepted: Yup.boolean()
    .oneOf(
      [true],
      "Du må bekrefte at moskeen har behandlet saken i sin ledelse",
    )
    .required("Dette feltet er påkrevd"),
  brregConfirmed: Yup.boolean()
    .oneOf([true], "Du må bekrefte at moskeen er registrert i BRREG")
    .required("Dette feltet er påkrevd"),
});

const CreateMosqueRequestPage: React.FC = () => {
  const { execute, error } = useFetch();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);
  const [prayerTimesTables, setPrayerTimesTables] = useState<
    Array<{ id: number; name: string }>
  >([]);

  const fetchLocations = async () => {
    const result = await execute("/api/v1/locations", "GET");
    if (result) {
      const sortedLocations = (result as Location[]).sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      setLocations(sortedLocations);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const handleSubmit = async (
    values: MosqueRequestFormValues,
    {
      resetForm,
      setFieldValue,
    }: {
      resetForm: () => void;
      setFieldValue: (field: string, value: any) => void;
    },
  ) => {
    const payload = {
      orgNr: values.orgNr,
      name: values.name,
      orgName: values.orgName,
      prayerTimeTable: values.prayerTimeTable || 0,
      mapOnly: true,
      streetAddress: values.streetAddress,
      postalCode: values.postalCode.trim(),
      city: values.city,
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      contactEmail: values.contactEmail,
      locationId: values.locationId || 0,
      asrMethod: values.asrMethod,
      requestDate: new Date().toISOString(),
    };

    const response = await execute(
      "/api/v1/mosques/approval/request",
      "POST",
      payload,
    );

    if (response) {
      setSuccessMessage("Din forespørsel er sendt til behandling.");
      resetForm();
      setLocations([]);
      setFieldValue("locationId", undefined);
      setPrayerTimesTables([]);
      fetchLocations();
    } else {
      setSuccessMessage(null);
    }
  };

  return (
    <div className={`container mt-5 ${styles.container}`}>
      <div className={styles.logoContainer}>
        <img src={iBonnLogo} alt="Logo" className={styles.logoImage} />
      </div>

      <h2>Forespørsel om Registrering av Moské</h2>
      <p className={styles.description}>
        Vennligst fyll ut skjemaet nedenfor for å sende inn en forespørsel om å
        registrere en ny moské. Vårt administrasjonsteam vil gå gjennom
        forespørselen din, og du vil bli kontaktet for videre oppfølging. Alle
        feltene er obligatoriske for å sikre nøyaktig registrering av
        moskéinformasjon.
      </p>
      <br />

      <Formik
        initialValues={{
          orgNr: "",
          name: "",
          orgName: "",
          streetAddress: "",
          postalCode: "",
          city: "",
          contactName: "",
          contactPhone: "",
          contactEmail: "",
          locationId: undefined,
          prayerTimeTable: undefined,
          disclaimerAccepted: false,
          brregConfirmed: false,
          asrMethod: AsrMethod.MIXED,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty, setFieldValue, values }) => (
          <FormikForm>
            <Card className={styles.card}>
              <Card.Body className={styles.cardBody}>
                <Form.Group controlId="formOrgNr" className={styles.formGroup}>
                  <Form.Label>Organisasjonsnummer*</Form.Label>
                  <Field
                    name="orgNr"
                    type="text"
                    className={styles.formControl}
                  />
                  <ErrorMessage
                    name="orgNr"
                    component="div"
                    className={styles.textDanger}
                  />
                </Form.Group>

                <Form.Group controlId="formName" className={styles.formGroup}>
                  <Form.Label>Moské Navn*</Form.Label>
                  <Field
                    name="name"
                    type="text"
                    className={styles.formControl}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className={styles.textDanger}
                  />
                </Form.Group>

                <Form.Group
                  controlId="formOrgName"
                  className={styles.formGroup}
                >
                  <Form.Label>Organisasjonsnavn*</Form.Label>
                  <Field
                    name="orgName"
                    type="text"
                    className={styles.formControl}
                  />
                  <ErrorMessage
                    name="orgName"
                    component="div"
                    className={styles.textDanger}
                  />
                </Form.Group>
                {/* 
                <Form.Group controlId="formLogo" className={styles.formGroup}>
                  <Form.Label>Last opp logo</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </Form.Group> */}

                <Form.Group
                  controlId="formStreetAddress"
                  className={styles.formGroup}
                >
                  <Form.Label>Adresse*</Form.Label>
                  <Field
                    name="streetAddress"
                    type="text"
                    className={styles.formControl}
                  />
                  <ErrorMessage
                    name="streetAddress"
                    component="div"
                    className={styles.textDanger}
                  />
                </Form.Group>

                <Form.Group
                  controlId="formPostalCode"
                  className={styles.formGroup}
                >
                  <Form.Label>Postnummer*</Form.Label>
                  <Field
                    name="postalCode"
                    type="text"
                    className={styles.formControl}
                  />
                  <ErrorMessage
                    name="postalCode"
                    component="div"
                    className={styles.textDanger}
                  />
                </Form.Group>

                <Form.Group controlId="formCity" className={styles.formGroup}>
                  <Form.Label>Sted*</Form.Label>
                  <Field
                    name="city"
                    type="text"
                    className={styles.formControl}
                  />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className={styles.textDanger}
                  />
                </Form.Group>

                <Form.Group
                  controlId="formLocation"
                  className={styles.formGroup}
                >
                  <Form.Label>Lokasjon*</Form.Label>
                  <Select
                    options={
                      locations.map((location) => ({
                        value: location.id,
                        label: location.name,
                      })) as SelectOption[]
                    }
                    value={
                      values.locationId
                        ? {
                            value: values.locationId,
                            label:
                              locations.find(
                                (loc) => loc.id === values.locationId,
                              )?.name || "",
                          }
                        : null
                    }
                    onChange={(option: SingleValue<SelectOption>) => {
                      const selectedLocation = locations.find(
                        (location) => location.id === option?.value,
                      );

                      setFieldValue("locationId", option?.value || null);

                      setPrayerTimesTables(
                        selectedLocation?.prayerTimesTables || [],
                      );
                    }}
                    placeholder="Søk etter lokasjon"
                    noOptionsMessage={() => "Ingen lokasjoner funnet"}
                  />
                  <ErrorMessage
                    name="locationId"
                    component="div"
                    className={styles.textDanger}
                  />
                </Form.Group>

                <Form.Group
                  controlId="formPrayerTimeTable"
                  className={styles.formGroup}
                >
                  <Form.Label>Bønn Tidstabell*</Form.Label>
                  <Field
                    as="select"
                    name="prayerTimeTable"
                    className={`form-control mb-3 ${styles.formControl} ${
                      prayerTimesTables.length === 0
                        ? styles.disabledDropdown
                        : ""
                    }`}
                    disabled={prayerTimesTables.length === 0}
                  >
                    <option value="">Velg en tidstabell</option>
                    {prayerTimesTables.map((table) => (
                      <option key={table.id} value={table.id}>
                        {table.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="prayerTimeTable"
                    component="div"
                    className={styles.textDanger}
                  />
                </Form.Group>

                <Form.Group
                  controlId="formAsrMethod"
                  className={styles.formGroup}
                >
                  <Form.Label>Asr Metode</Form.Label>
                  <Field
                    as="select"
                    name="asrMethod"
                    className="form-control mb-3"
                    placeholder="Velg Asr Metode"
                  >
                    <option value={AsrMethod.MIXED}>Mixed</option>
                    <option value={AsrMethod.ONE_X}>1x</option>
                    <option value={AsrMethod.TWO_X}>2x</option>
                  </Field>
                  <ErrorMessage
                    name="asrMethod"
                    component="div"
                    className={styles.textDanger}
                  />
                </Form.Group>

                <Form.Group
                  controlId="formContactName"
                  className={styles.formGroup}
                >
                  <Form.Label>Kontaktperson*</Form.Label>
                  <Field
                    name="contactName"
                    type="text"
                    className={styles.formControl}
                  />
                  <ErrorMessage
                    name="contactName"
                    component="div"
                    className={styles.textDanger}
                  />
                </Form.Group>

                <Form.Group
                  controlId="formContactPhone"
                  className={styles.formGroup}
                >
                  <Form.Label>Telefonnummer*</Form.Label>
                  <Field
                    name="contactPhone"
                    type="number"
                    className={styles.formControl}
                  />
                  <ErrorMessage
                    name="contactPhone"
                    component="div"
                    className={styles.textDanger}
                  />
                </Form.Group>

                <Form.Group
                  controlId="formContactEmail"
                  className={styles.formGroup}
                >
                  <Form.Label>E-post*</Form.Label>
                  <Field
                    name="contactEmail"
                    type="email"
                    className={styles.formControl}
                  />
                  <ErrorMessage
                    name="contactEmail"
                    component="div"
                    className={styles.textDanger}
                  />
                </Form.Group>

                <Form.Group
                  className={`${styles.formGroup} ${styles.checkBoxGroup}`}
                >
                  <Field
                    type="checkbox"
                    name="disclaimerAccepted"
                    className={styles.checkbox}
                  />
                  <div className={styles.checkBoxLabelGroup}>
                    <label
                      htmlFor="disclaimerAccepted"
                      className={styles.label}
                    >
                      Jeg bekrefter at moskeen har behandlet saken i sin
                      ledelse.*
                    </label>
                    <ErrorMessage
                      name="disclaimerAccepted"
                      component="div"
                      className={styles.textDanger}
                    />
                  </div>
                </Form.Group>

                <Form.Group
                  className={`${styles.formGroup} ${styles.checkBoxGroup}`}
                >
                  <Field
                    type="checkbox"
                    name="brregConfirmed"
                    className={styles.checkbox}
                  />
                  <div className={styles.checkBoxLabelGroup}>
                    <label htmlFor="brregConfirmed" className={styles.label}>
                      Jeg bekrefter at moskeen er registrert i
                      Brønnøysundregisteret (BRREG).*
                    </label>
                    <ErrorMessage
                      name="brregConfirmed"
                      component="div"
                      className={styles.textDanger}
                    />
                  </div>
                </Form.Group>

                <Button
                  variant="dark"
                  type="submit"
                  className={styles.largeButton}
                  disabled={!(isValid && dirty)}
                >
                  Send Forespørsel
                </Button>
              </Card.Body>
            </Card>

            {successMessage && (
              <Alert variant="success" className={styles.alert}>
                {successMessage}
              </Alert>
            )}
            {error && (
              <Alert variant="danger" className={styles.alert}>
                {error.text ||
                  "En feil oppstod under innsending av forespørselen."}
              </Alert>
            )}
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default CreateMosqueRequestPage;
