import { ErrorMessage, Field } from "formik";
import React from "react";

interface AdminTextFieldProps {
  name: string;
  label?: string;
  type?: string;
  className?: string;
  disabled?: boolean;
}

const AdminTextField: React.FC<AdminTextFieldProps> = ({
  name,
  label,
  type = "text",
  className = "form-control mb-3",
  disabled = false,
}) => (
  <div className="form-group">
    {label && <label htmlFor={name}>{label}</label>}
    <Field
      type={type}
      id={name}
      name={name}
      className={className}
      disabled={disabled}
    />
    <ErrorMessage name={name} component="div" className="text-danger" />
  </div>
);

export default AdminTextField;
